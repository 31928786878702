import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Icon } from '../../../components/icons'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
import { Path } from '../../../router/paths'
import { useCollaboratorPayments } from './_hooks/useCollaboratorPayments'
import { Select } from '../../../components/ui/select'
import { DataComTraçoParaDataComBarra } from '../../../utils/dates'
import { Form } from '../../../components/ui/form'

export const AdminCollaboratorPayments = () => {
  const {
    loading,
    search,
    // assinaturasDatas,
    formContext,
    setFormContext,
    infosContext,
    setSearch,
    dataFiltered,
    FetchGetDatas,
    FetchGetInfos,
    AddPlanInList,
    DeletePlansOfList,
    RemoveEndDate,
    AddEndDate,
    setDtStart,
    HandleEditClient,
  } = useCollaboratorPayments()

  return (
    <>
      <Page.Root className="is-fullheight-with-navbar mt-6 p-6">
        <Form.Root
          action={() => {
            HandleEditClient()
          }}
        >
          {/* Infomações dos planos */}
          <Column.Root className="mt-6 pt-5">
            <Column.Root>
              <Column.Content className="my-5">
                <p className="subtitle is-size-4">
                  <Icon size={30} className={'mr-3'}>
                    bill
                  </Icon>
                  Área Financeira do Colaborador
                </p>
              </Column.Content>
            </Column.Root>
            <Column.Content className="has-text-right">
              <Button.Root align="right">
                <Button.Content>
                  <Button.Bt
                    color="is-dark"
                    colorText="has-text-white"
                    action={() => {
                      FetchGetDatas()
                      FetchGetInfos()
                    }}
                    loading={loading}
                  >
                    <Icon size={15}>sync</Icon>
                  </Button.Bt>
                </Button.Content>
              </Button.Root>
            </Column.Content>
          </Column.Root>
          {/* Lista de Assinaturas do cliente */}
          <Column.Root className="is-mobile mr-6 pr-2">
            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Planos
                </Input.Label>
                <Select.Root>
                  <Select.Content
                    color="is-grey"
                    className="is-fullwidth is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'select_planos'}
                    // disabled={disabledList?.type}
                  >
                    <Select.Option value={null} title={''} />
                    <Select.Search value={search} setValue={setSearch} />
                    {(infosContext?.plans || []).map((item, index) => {
                      return <Select.Option key={index} value={item.id} title={item.name} />
                    })}
                  </Select.Content>
                </Select.Root>
              </Input.Root>
            </Column.Content>
            <Column.Content size="is-1 mr-5">
              <Button.Bt
                className="mt-5 is-rounded"
                color="is-dark"
                colorText=""
                action={() => {
                  AddPlanInList(formContext.select_planos)
                }}
                loading={loading}
              >
                Adicionar
              </Button.Bt>
            </Column.Content>
          </Column.Root>

          <div className="box" style={{ marginTop: -30 }}>
            <div className="table-container">
              <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                <Table.Head>
                  <Table.Row>
                    <Table.Cell>Nº</Table.Cell>
                    <Table.Cell>Nome</Table.Cell>
                    <Table.Cell>Valor</Table.Cell>
                    <Table.Cell>Data Inicio</Table.Cell>
                    <Table.Cell>Data Término</Table.Cell>
                    <Table.Cell>Ações</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {(formContext?.plans || []).map((plan, index) => {
                    return (
                      <Table.Row key={`planslist_${plan.index}`}>
                        <Table.Cell className="is-vcentered" size={10}>
                          {plan.index}
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <span title={plan.nome}>{plan.name}</span>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">R$ {plan.value}</Table.Cell>
                        <Table.Cell className="is-vcentered" size={200}>
                          <Input.Prompt
                            type="date"
                            className="is-rounded"
                            value={plan?.dtStart}
                            // setValue={(value) => {
                            //   return setFormContext((prev) => {
                            //     const newPlan = { ...plan, dtStart: value }
                            //     return {
                            //       ...prev,
                            //       plans: [...(prev.plans || []).filter((item) => item.index !== plan.index), newPlan],
                            //     }
                            //   })
                            // }}
                            setValue={(value) => {
                              return setDtStart(plan.id, value)
                            }}
                            required={true}
                            // disabled={disabledList?.dtStart}
                          ></Input.Prompt>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered" size={200}>
                          {plan.typeExpired === '0' ? (
                            <p className="is-size-7 has-text-danger">(Não Expira)</p>
                          ) : (
                            <>
                              {plan.index < (formContext?.plans || []).length - 1 ? (
                                <p>{DataComTraçoParaDataComBarra(plan.dtEnd)}</p>
                              ) : (
                                <>
                                  <Input.Prompt
                                    type="date"
                                    className="is-rounded"
                                    value={plan.dtEnd}
                                    setValue={(value) => {
                                      return setFormContext((prev) => {
                                        const newPlan = { ...plan, dtEnd: value }
                                        return {
                                          ...prev,
                                          plans: [
                                            ...(prev.plans || []).filter((item) => item.index !== plan.index),
                                            newPlan,
                                          ],
                                        }
                                      })
                                    }}
                                    required={true}
                                    // disabled={disabledList?.dtEnd}
                                  ></Input.Prompt>
                                </>
                              )}

                              {!plan?.dtEnd ? (
                                <p className="is-size-7 has-text-danger">(Não Expira)</p>
                              ) : (
                                <p className="is-size-7">({plan?.typeExpired} Dias)</p>
                              )}
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <Button.Root>
                            {plan.typeExpired !== '0' &&
                              plan.index >= (formContext?.plans || []).length - 1 &&
                              formContext?.dtStart && (
                                <Button.Content>
                                  {plan?.dtEnd ? (
                                    <Button.Bt
                                      className=""
                                      color="is-warning"
                                      size={'is-small'}
                                      colorText="has-text-white"
                                      action={() => {
                                        RemoveEndDate(plan?.id)
                                      }}
                                      loading={loading}
                                      title={'Remover Data de Término'}
                                    >
                                      <Icon size={15}>sync</Icon>
                                      <Icon size={15}>x</Icon>
                                    </Button.Bt>
                                  ) : (
                                    <Button.Bt
                                      className=""
                                      color="is-success"
                                      size={'is-small'}
                                      colorText="has-text-white"
                                      action={() => {
                                        AddEndDate(plan?.id)
                                      }}
                                      loading={loading}
                                      title={'Adicionar Data de Término'}
                                    >
                                      <Icon size={15}>sync</Icon>+
                                    </Button.Bt>
                                  )}
                                </Button.Content>
                              )}
                            <Button.Content>
                              <Button.Bt
                                className=""
                                color="is-danger"
                                size={'is-small'}
                                colorText="has-text-white"
                                action={() => {
                                  DeletePlansOfList(plan?.id)
                                }}
                                loading={loading}
                              >
                                <Icon size={15}>trash</Icon>
                              </Button.Bt>
                            </Button.Content>
                          </Button.Root>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table.Root>
            </div>
          </div>
          {/* Titulo */}
          <Column.Root className="mt-6 pt-5">
            <Column.Root>
              <Column.Content>
                <h2 className="subtitle is-size-4">Histórico</h2>
              </Column.Content>
            </Column.Root>
            <Column.Content className="has-text-right"></Column.Content>
          </Column.Root>
          {/* Barra de pesquisa */}
          <Column.Root className="is-mobile">
            <Column.Content>
              <Input.Root>
                <Input.Contents alignIcon={'left'}>
                  <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
                  <Input.Icon align="is-left">
                    <Icon size={20}>search</Icon>
                  </Input.Icon>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>
          {/* Tabela de dados */}
          <Column.Root className="is-mobile">
            <Column.Content>
              <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                <Table.Head>
                  <Table.Row key={'table_payments_head'}>
                    <Table.Cell>Descrição</Table.Cell>
                    <Table.Cell>Data</Table.Cell>
                    <Table.Cell>Valor</Table.Cell>
                    <Table.Cell>Situação</Table.Cell>
                    <Table.Cell>Ações</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {dataFiltered.map((value) => {
                    return (
                      <>
                        <Table.Row key={value.id}>
                          <Table.Cell className="is-size-7 is-vcentered">{value.description}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">{value.data}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">R$ {value.value}</Table.Cell>
                          <Table.Cell className="is-size-7 is-vcentered">
                            {value.status ? 'Pago' : 'Pendente'}
                          </Table.Cell>
                          <Table.Cell className="is-vcentered">
                            <div style={{ marginBottom: 5, marginTop: 5 }}>
                              <Button.Root align="centered">
                                <Button.Content>
                                  {value.status ? (
                                    <Button.A
                                      className={'mx-2'}
                                      color="is-warning"
                                      link={Path.ClientEditSectors(value.id)}
                                      loading={loading}
                                    >
                                      <Icon size={15}>print</Icon>
                                    </Button.A>
                                  ) : (
                                    <Button.A
                                      className={'mx-2'}
                                      color="is-success"
                                      link={Path.ClientEditSectors(value.id)}
                                      loading={loading}
                                    >
                                      <Icon size={15}>sync</Icon>
                                    </Button.A>
                                  )}
                                </Button.Content>
                              </Button.Root>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      </>
                    )
                  })}
                </Table.Body>
              </Table.Root>
            </Column.Content>
          </Column.Root>
          <Column.Root className="mt-7 mb-6">
            <Column.Content>
              <Button.Root align="right">
                <Button.Content>
                  <Button.A color="is-light" link={Path.AdminCollaborators} loading={loading}>
                    Cancelar
                  </Button.A>
                </Button.Content>
                <Button.Content>
                  <Button.Bt
                    className=""
                    color="is-success"
                    colorText="has-text-white"
                    type={'submit'}
                    loading={loading}
                  >
                    Salvar
                  </Button.Bt>
                </Button.Content>
              </Button.Root>
            </Column.Content>
          </Column.Root>
        </Form.Root>
      </Page.Root>
    </>
  )
}
