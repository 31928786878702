export const createSchemaPlans = (values) => {
  return {
    name: values.name,
    desc: values.desc,
    value: values.value,
    codCupom: values.codCupom,
    typeExpired: values.typeExpired,
    ...(values?.nAliqISS && { nAliqISS: values?.nAliqISS ? Number(values.nAliqISS) : 0 }),
    ...(values?.nAliqPIS && { nAliqPIS: values?.nAliqPIS ? Number(values.nAliqPIS) : 0 }),
    ...(values?.nAliqCOFINS && { nAliqCOFINS: values?.nAliqCOFINS ? Number(values.nAliqCOFINS) : 0 }),
    ...(values?.nAliqIR && { nAliqIR: values?.nAliqIR ? Number(values.nAliqIR) : 0 }),
    ...(values?.nAliqINSS && { nAliqINSS: values?.nAliqINSS ? Number(values.nAliqINSS) : 0 }),
    ...(values?.nRedBaseINSS && { nRedBaseINSS: values?.nRedBaseINSS ? Number(values.nRedBaseINSS) : 0 }),
    ...(values?.nRedBasePIS && { nRedBasePIS: values?.nRedBasePIS ? Number(values.nRedBasePIS) : 0 }),
    ...(values?.nRedBaseCOFINS && { nRedBaseCOFINS: values?.nRedBaseCOFINS ? Number(values.nRedBaseCOFINS) : 0 }),
    ...(values?.cRetISS && { cRetISS: values?.cRetISS }),
    ...(values?.cRetPIS && { cRetPIS: values?.cRetPIS }),
    ...(values?.cRetCOFINS && { cRetCOFINS: values?.cRetCOFINS }),
    ...(values?.cRetCSLL && { cRetCSLL: values?.cRetCSLL }),
    ...(values?.cRetIR && { cRetIR: values?.cRetIR }),
    ...(values?.cRetINSS && { cRetINSS: values?.cRetINSS }),
    ...(values?.lDeduzISS && { lDeduzISS: !!values?.lDeduzISS }),
  }
}
