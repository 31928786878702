import { RequestOmie } from '../../../../api/omie'
import {
  generateIdDocument,
  getDocumentsWithQuerys,
  getSubDocuments,
  setDocument,
} from '../../../../firebase/firestore'
import { DateTimeNow } from '../../../../utils/dates'

export const useHomeAdministration = () => {
  const faturar = async () => {
    const tableOS = 'OrdensServico'
    const today = new Date()
    const dayNow = today.getDate()

    const OSOmie = () => {
      const endpoint = 'https://app.omie.com.br/api/v1/servicos/os/'
      return {
        incluir: async (param) => {
          const call = 'IncluirOS'
          console.log(param)
          const response = await RequestOmie({ endpoint, call, param })
          return response
        },
      }
    }

    const sumDaysInDate = (date, days, typeReturn = 'dd/mm/aaaa') => {
      if (String(date).includes('-')) {
        date = new Date(date)
      }
      const futureDate = new Date()
      futureDate.setDate(date.getDate() + days)
      const day = futureDate.getDate().toString().padStart(2, '0') // Adiciona zero à esquerda se necessário
      const month = (futureDate.getMonth() + 1).toString().padStart(2, '0') // Mês começa em 0
      const year = futureDate.getFullYear()
      if (typeReturn === 'dd/mm/aaaa') {
        return `${day}/${month}/${year}`
      }
      if (typeReturn === 'aaaa-mm-dd') {
        return `${year}-${month}-${day}`
      }
    }

    const getActivePlan = (plans) => {
      // Ordena os planos por `dtStart` em ordem decrescente (mais recente primeiro)
      const sortedPlans = plans.sort((a, b) => new Date(b.dtStart) - new Date(a.dtStart))

      // Filtra os planos ativos
      for (const plan of sortedPlans) {
        const dtStart = new Date(plan.dtStart)
        const dtEnd = plan.dtEnd ? new Date(plan.dtEnd) : null

        // Verifica se está dentro do intervalo de datas ou se é um plano sem término
        if (today >= dtStart && (!dtEnd || today <= dtEnd)) {
          return plan // Retorna o primeiro plano ativo encontrado
        }
      }

      // Retorna null se nenhum plano estiver ativo
      return null
    }

    const createSchemaOrdemDeServicoOmie = (values) => {
      return {
        Cabecalho: {
          cCodIntOS: values.idInvoce,
          cCodParc: '000', // Pagamento A Vista
          cEtapa: '20',
          dDtPrevisao: sumDaysInDate(today, 15, 'dd/mm/aaaa'),
          nCodCli: values.idClientOmie,
          nQtdeParc: 1,
        },
        Departamentos: [],
        Email: {
          ...(values.type_invoices === 'boleto' && { cEnvBoleto: 'S' }),
          ...(values.type_invoices === 'pix' && { cEnvPix: 'S' }),
          cEnvLink: 'S',
          cEnviarPara: values.responsavel_email,
        },
        InformacoesAdicionais: {
          cDadosAdicNF: 'Faturamento keap staff gerado automaticamente.',
          cCodCateg: '1.01.02',
          nCodCC: 10743321257,
        },
        ServicosPrestados: [
          {
            nCodServico: values.plan.idOmie,
            nQtde: 1,
            nValUnit: values.plan.value,
          },
        ],
      }
    }

    const createSchemaOrdemDeServico = (values) => {
      return {
        dataOmie: values.dataOmie,
        idClient: values.idClient,
        idClientOmie: values.idClientOmie,
        idPlan: values.plan.id,
        idPlanOmie: values.plan.idOmie,
        dt_created: DateTimeNow(), // Data/hora de criação da OS
        valueOS: values.plan.value, // Valor da OS
        status: 'awaitingBilling', // Status: "awaitingBilling", "billing", "sucessBilling", "failure"
        descricao: values.plan.name, // Descrição da OS (ex: "Manutenção de rotina")
        billingAttempts: 0, // Número de tentativas de faturamento
        billingError: '', // Detalhes do erro em caso de falha no faturamento
        dt_billing: DateTimeNow(), // Data/hora em que foi faturada (se aplicável)
      }
    }

    const query = [{ field: 'status', operator: '!=', value: 'disabled' }]
    const getClients = await getDocumentsWithQuerys('clientes', query)

    if (getClients.length === 0) return

    const queryGetPlans = getClients.map(async (client) => {
      const getPlans = await getSubDocuments('clientes', client.id, 'plans')
      const planActive = getActivePlan(getPlans)
      return {
        plan: planActive,
        dt_invoices: client.dt_invoices,
        idClient: client.id,
        idClientOmie: client.idClientOmie,
        type_invoices: client.type_invoices,
        responsavel_email: client.responsavel_email,
        lastInvoiceDate: client?.lastInvoiceDate || '1972-01-01',
      }
    })

    const plansActives = await Promise.all(queryGetPlans)

    const plansFromInvoices = plansActives
      .filter((plan) => plan.plan !== null)
      .filter((plan) => dayNow >= Number(plan.dt_invoices))
      .filter((plan) => today > new Date(plan.lastInvoiceDate))
    console.log(plansFromInvoices)

    const enrichedInvoices = plansFromInvoices.map((values) => {
      return { ...values, dataOmie: createSchemaOrdemDeServicoOmie(values) }
    })

    const createInvoices = enrichedInvoices.map((values) => {
      return createSchemaOrdemDeServico(values)
    })

    const setInvoices = createInvoices.map(async (values) => {
      const idOS = await generateIdDocument(tableOS)
      const params = [{ ...values.dataOmie, Cabecalho: { ...values.dataOmie.Cabecalho, cCodIntOS: idOS } }]
      let incluirServicoOmie = false
      const data = { ...values, idOS }
      delete data.dataOmie

      try {
        console.log('params: ', params)
        incluirServicoOmie = await OSOmie().incluir(params)
        data.idOSOmie = incluirServicoOmie.nCodOS
        data.cNumOS = incluirServicoOmie.cNumOS
        console.log('response Omie: ', incluirServicoOmie)
      } catch (error) {
        data.billingError = error.message
        data.billingAttempts = 1
        data.status = 'reCreateOS'
      }

      console.log('data: ', data)

      await setDocument(tableOS, idOS, data)
      console.log('... Salvando OS in ' + tableOS)
    })

    await Promise.all(setInvoices)
  }

  return { faturar }
}
