import { useState } from 'react'

import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { Table } from '../../../../components/ui/table'
import { ConvertTimestampToDateTime } from '../../../../utils/dates'

export const ModalHistoryBilling = ({ loading, active = true, setActive, dataContext = {} }) => {
  console.log(dataContext)
  const [req, setReq] = useState(false)

  if (active) {
    return (
      <div>
        <Modal.Root active={active}>
          <Modal.Contents witdh={1000}>
            <Modal.Header title={'Histórico de processamento'}>
              <Modal.HeaderClose
                setActive={(value) => {
                  setActive(value)
                  setReq(value)
                }}
              ></Modal.HeaderClose>
            </Modal.Header>

            <Modal.Body>
              <>
                <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                  <Table.Head>
                    <Table.Row key={'tableEventsHead'}>
                      <Table.Cell>Descrição</Table.Cell>
                      <Table.Cell>Data</Table.Cell>
                      <Table.Cell>Processado por</Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {(dataContext?.history || []).map((hist, index) => {
                      console.log(history)
                      return (
                        <Table.Row key={`history_${index}`}>
                          <Table.Cell>{hist[0]}</Table.Cell>
                          <Table.Cell>{ConvertTimestampToDateTime(hist[1])}</Table.Cell>
                          <Table.Cell>{hist[2]}</Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table.Root>
                {req && (
                  <div className="box">
                    <p>Requisição enviada a OMIE</p>
                    <br />
                    <pre>{dataContext?.reqOmie}</pre>
                  </div>
                )}
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button.Bt
                action={() => {
                  setReq((prev) => !prev)
                }}
              >
                Requisição OMIE
              </Button.Bt>
            </Modal.Footer>
          </Modal.Contents>
        </Modal.Root>
      </div>
    )
  } else {
    return <></>
  }
}
