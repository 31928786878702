export const getCollaboratorStatusType = (collaboratorStatusNumber) => {
  let statusType

  switch (collaboratorStatusNumber) {
    case 0:
      statusType = 'Em Análise'
      break
    case 1:
      statusType = 'Ativo'
      break
    case 2:
      statusType = 'Pendente'
      break
    default:
      statusType = 'Bloqueado'
      break
  }

  return statusType
}

export const getCollaboratorFavoriteStatusType = (collaboratorStatus, reason = null) => {
  let statusType
  let statusColor

  if (!collaboratorStatus && reason) {
    statusType = 'Convite recusado'
    statusColor = 'is-danger'
  } else if (!collaboratorStatus && !reason) {
    statusType = 'Convite enviado'
    statusColor = 'is-warning'
  } else if (collaboratorStatus && !reason) {
    statusType = 'Convite aceito'
    statusColor = 'is-success'
  } else {
    statusType = 'Status desconhecido'
    statusColor = ''
  }

  return {
    statusType,
    statusColor,
  }
}
