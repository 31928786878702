export const TratamentoError = (error) => {
  let errorMessage = String(error.message).toLowerCase()

  if (errorMessage.includes('missing or insufficient permissions')) {
    errorMessage = 'Sessão expirada, realize o login novamente...'
  }

  if (errorMessage.includes('auth/invalid-credential')) {
    errorMessage = 'E-mail ou senha Incorretos, verifique-os, e tente novamente'
  }

  return errorMessage
}
