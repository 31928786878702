import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { ClientsLayout } from './_form/clientsFormLayout'
import { Icon } from '../../../components/icons'
import { useEditClients } from './_hooks'
import { Input } from '../../../components/ui/input'
import { Button } from '../../../components/ui/button'
import { GetStatusClient } from './_utils'

export const AEditClients = () => {
  const {
    id,
    status,
    navigate,
    loading,
    setLoading,
    setFormContext,
    formContext,
    infosContext,
    HandleEditClient,
    FetchPrintPage,
    FetchResetPasswordAdmin,
    FetchaddUserAdmin,
    FetchLockAndUnlockClient,
  } = useEditClients()

  return (
    <Page.Section className="is-fullheight-with-navbar  mt-6 pt-6">
      <Form.Root
        action={() => {
          HandleEditClient()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Cliente</p>
          </Column.Content>
        </Column.Root>

        <Column.Root>
          <Column.Content>
            <Input.Root className="mb-3">
              <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                Situação
              </Input.Label>
              <Input.Contents>
                <Input.ReadOnly className="is-rounded">{status}</Input.ReadOnly>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
          <Column.Content size="">
            <Column.Root>
              <Column.Content className="has-text-centered" type="button">
                <Button.Bt
                  color={GetStatusClient(formContext?.status).action[0][2]}
                  type="button"
                  className="mx-1"
                  size={'is-small'}
                  loading={loading}
                  action={FetchLockAndUnlockClient}
                >
                  <Icon>{GetStatusClient(formContext?.status).action[0][1]}</Icon>
                </Button.Bt>
                <p className="is-size-7 has-text-grey">{GetStatusClient(formContext?.status).action[0][0]}</p>
              </Column.Content>

              <Column.Content className="has-text-centered" type="button">
                <Button.Bt
                  color="is-light"
                  type="button"
                  className="mx-1"
                  size={'is-small'}
                  action={FetchPrintPage}
                  loading={loading}
                >
                  <Icon>print</Icon>
                </Button.Bt>
                <p className="is-size-7 has-text-grey">Imprimir</p>
              </Column.Content>

              {formContext?.userAdmin ? (
                <Column.Content className="has-text-centered" type="button">
                  <Button.Bt
                    color="is-light"
                    type="button"
                    className="mx-1"
                    size={'is-small'}
                    action={FetchResetPasswordAdmin}
                    loading={loading}
                  >
                    <Icon>password</Icon>
                  </Button.Bt>
                  <p className="is-size-7 has-text-grey">Resetar Senha</p>
                </Column.Content>
              ) : (
                <Column.Content className="has-text-centered" type="button">
                  <Button.Bt
                    color="is-light"
                    type="button"
                    className="mx-1"
                    size={'is-small'}
                    action={FetchaddUserAdmin}
                    loading={loading}
                  >
                    <Icon>add</Icon>
                  </Button.Bt>
                  <p className="is-size-7 has-text-grey">Criar Login</p>
                </Column.Content>
              )}
            </Column.Root>
          </Column.Content>
        </Column.Root>

        <ClientsLayout
          navigate={navigate}
          loading={loading}
          setLoading={setLoading}
          values={formContext}
          setValues={setFormContext}
          options={infosContext}
          disabledList={{}}
          type={'edit'}
          clientId={id}
        />
      </Form.Root>
    </Page.Section>
  )
}
