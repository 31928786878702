import React from 'react'

import { Icon } from '../../../components/icons'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
import { Page } from '../../../components/layouts/page'
import { Path } from '../../../router/paths'
import { useClientBlockeds } from './_hooks/useClientBlockeds'
import { PaginationFunctional } from '../../../components/ui/pagination/PaginationFunctional'
import { Form } from '../../../components/ui/form'
import { Image } from '../../../components/ui/image'

export const ClientBlockeds = () => {
  const { page, setPage, loading, search, setSearch, dataContext, FetchGetDatas, HandleDeleteFavorite } =
    useClientBlockeds()

  return (
    <>
      <Page.Root className="is-fullwidth">
        <Column.Root className="mt-6 pt-6">
          <Column.Content>
            <p className="subtitle is-size-4">
              <Icon size={30} className={'mr-3'}>
                block
              </Icon>
              Bloqueados
            </p>
          </Column.Content>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    FetchGetDatas()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
              <Button.Content>
                <Button.A
                  className=""
                  color="is-success"
                  colorText="has-text-white"
                  link={Path.ClientAddTeams}
                  loading={loading}
                >
                  +
                </Button.A>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
        {dataContext && !loading ? (
          <>
            <Form.Root
              action={() => {
                FetchGetDatas()
              }}
            >
              <Column.Root className="">
                <Column.Content>
                  <Input.Root>
                    <Input.Contents alignIcon={'left'}>
                      <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
                      <Input.Icon align="is-left">
                        <Icon size={20}>search</Icon>
                      </Input.Icon>
                    </Input.Contents>
                  </Input.Root>
                </Column.Content>
              </Column.Root>
            </Form.Root>
            <Column.Root className="">
              <Column.Content>
                <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered ">
                  <Table.Head>
                    <Table.Row>
                      <Table.Cell size={10}>Foto</Table.Cell>
                      <Table.Cell>Nome</Table.Cell>
                      <Table.Cell>CPF</Table.Cell>
                      <Table.Cell>E-mail</Table.Cell>
                      <Table.Cell>Motivo do Bloqueio</Table.Cell>
                      <Table.Cell>Criado por</Table.Cell>
                      <Table.Cell>Criado em</Table.Cell>
                      <Table.Cell>Informações</Table.Cell>
                      <Table.Cell>Ações</Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {(dataContext?.blockeds?.data || []).map((value) => {
                      return (
                        <>
                          <Table.Row>
                            <Table.Cell className="is-size-7 is-vcentered">
                              <Image.Root size={'is-48x48'}>
                                <Image.Content url={value?.photoUrl} />
                              </Image.Root>
                            </Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">{value?.name}</Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">{value.cpf}</Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">{value.email}</Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">{value.reason}</Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">{value.createdBy}</Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">{value.dt_created}</Table.Cell>
                            <Table.Cell className="is-size-7 is-vcentered">{value?.infoExtra || ''}</Table.Cell>
                            <Table.Cell className="is-vcentered">
                              <div style={{ marginBottom: 5, marginTop: 5 }}>
                                <Button.Root align="centered">
                                  <Button.Content>
                                    <Button.Bt
                                      size="is-small"
                                      color="is-danger"
                                      action={() => {
                                        HandleDeleteFavorite(value.uuid)
                                      }}
                                      loading={loading}
                                    >
                                      <Icon size={15}>trash</Icon>
                                    </Button.Bt>
                                  </Button.Content>
                                </Button.Root>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        </>
                      )
                    })}
                  </Table.Body>
                </Table.Root>
              </Column.Content>
            </Column.Root>
            <PaginationFunctional
              paginationData={dataContext?.blockeds?.pagination || {}}
              setValuePage={setPage}
              valuePage={page}
            ></PaginationFunctional>
          </>
        ) : (
          <Column.Root>
            <Column.Content></Column.Content>
            <Column.Content>
              <Icon>loading</Icon>
            </Column.Content>
            <Column.Content></Column.Content>
          </Column.Root>
        )}
      </Page.Root>
    </>
  )
}
