import { useGlobalContext } from '../../../../context/ContextGlobal'

export const useValidateCollaboratorModalForm = () => {
  const { loading, formContext, setFormContext } = useGlobalContext()

  const handleUpdateEditReason = (field, event, index = null) => {
    const newReason = event.target.value // Valor do input
    const modalCollaboratorSubmitDisabled = !newReason

    setFormContext((prevState) => {
      const inEdition = { ...prevState.inEdition }

      if (index !== null) {
        // Atualiza o reason no item específico do array emergencyContacts
        if (Array.isArray(inEdition.emergencyContacts)) {
          const updatedEmergencyContacts = inEdition.emergencyContacts.map((contact, idx) => {
            if (idx === index) {
              const updatedContact = { ...contact }

              if (updatedContact[field]) {
                updatedContact[field] = { ...updatedContact[field], reason: newReason }
              }

              return updatedContact
            }

            return contact
          })

          inEdition.emergencyContacts = updatedEmergencyContacts
        }
      } else {
        // Atualiza o reason do campo específico no objeto inEdition
        if (inEdition[field]) {
          inEdition[field] = { ...inEdition[field], reason: newReason }
        }
      }

      return { ...prevState, inEdition, modalCollaboratorSubmitDisabled }
    })
  }

  const handleUpdateAllEditReasons = (e) => {
    const newReason = e.target.value // O motivo geral que foi alterado
    const modalCollaboratorSubmitDisabled = !newReason

    // Atualiza o estado de inEdition preservando a estrutura original
    setFormContext((prevContext) => {
      // Copia a estrutura de `inEdition` atual
      const updatedInEdition = { ...prevContext.inEdition }

      // Atualiza os "motivos" de todos os itens, mantendo os outros campos
      Object.keys(updatedInEdition).forEach((itemKey) => {
        const item = updatedInEdition[itemKey]

        if (Array.isArray(item)) {
          // Caso seja um array (ex: emergencyContacts), atualiza os "motivos" de todos os contatos
          item.forEach((contact) => {
            Object.keys(contact).forEach((fieldKey) => {
              if (Object.prototype.hasOwnProperty.call(contact[fieldKey], 'reason')) {
                // Atualiza se estiver vazio ou se foi alterado em massa
                if (!contact[fieldKey].reason || contact[fieldKey].updatedInBulk) {
                  contact[fieldKey].reason = newReason
                  contact[fieldKey].updatedInBulk = true // Marca que foi alterado em massa
                }
              }
            })
          })
        } else if (Object.prototype.hasOwnProperty.call(item, 'reason')) {
          // Caso não seja um array, apenas atualiza o "motivo" do campo
          if (!item.reason || item.updatedInBulk) {
            item.reason = newReason
            item.updatedInBulk = true // Marca que foi alterado em massa
          }
        }
      })

      return {
        ...prevContext,
        inEdition: updatedInEdition,
        modalCollaboratorSubmitDisabled,
      }
    })
  }

  return {
    loading,
    formContext,
    handleUpdateEditReason,
    handleUpdateAllEditReasons,
  }
}
