import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
// import { getSubDocuments } from '../../../../firebase/firestore'
import { GetPaginationCandidacysCollaborators } from '../../../../firebase/functions'
import { statusStaff } from '../../../client/events/_utils/statusCandidate'
// import { Path } from '../../../../router/paths'
// import { getSubDocuments } from '../../../../firebase/firestore'

export const useCollaboratorCandidacys = () => {
  const {
    // user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    // formContext,
    // setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const [search, setSearch] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const [paginationValue, setPaginationValue] = useState(1)

  const candidacys = dataContext?.candidacys?.data ?? {}

  const { id } = useParams()

  const getDatas = async () => {
    const query = {}
    const getCandidacys = await GetPaginationCandidacysCollaborators(paginationValue, 100, id, query)
    console.log(getCandidacys)
    setDataContext({ candidacys: getCandidacys })
  }

  const nextPage = async () => {
    const currentPage = dataContext?.colaboradores?.pagination?.currentPage
    setPaginationValue(currentPage + 1)
    await getDatas()
  }

  const previousPage = async () => {
    const currentPage = dataContext?.colaboradores?.pagination?.currentPage
    setPaginationValue(currentPage - 1)
    await getDatas()
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const dataFiltered =
    search !== ''
      ? Object.values(candidacys).filter((candidate) => {
          const searchString =
            `${candidate.name}|${candidate.date}|${candidate.position}|${candidate.status}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : Object.values(candidacys)

  useEffect(() => {
    FetchGetDatas()
  }, [paginationValue])

  return {
    loading,
    search,
    setSearch,
    searchParams,
    setSearchParams,
    dataFiltered,
    dataContext,
    setDataContext,
    infosContext,
    FetchGetDatas,
    nextPage,
    previousPage,
    statusStaff,
  }
}
