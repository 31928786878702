import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { useAdminCollaboratorPositions } from './_hooks/useAdminCollaboratorPositions'
import { CollaboratorsPositionsForm } from './_components/collaboratorPositionsForm'
import { Icon } from '../../../components/icons'

export const AdminCollaboratorPositions = () => {
  const { loading, handleEditPositions } = useAdminCollaboratorPositions()

  return (
    <>
      <Page.Section className="is-fullheight-with-navbar mt-6 pt-6">
        <Form.Root
          action={() => {
            handleEditPositions()
          }}
          className="mt-5"
        >
          <Column.Root>
            <Column.Content className="my-5">
              <p className="subtitle is-size-4">
                <Icon size={30} className={'mr-3'}>
                  job
                </Icon>
                Cargos do Colaborador
              </p>
            </Column.Content>
          </Column.Root>

          {loading && (
            <Column.Root className="is-mobile">
              <Column.Content></Column.Content>
              <Column.Content className="is-1">
                <Icon>loading</Icon>
                <p className="is-size-7 has-text-grey">carregando...</p>
              </Column.Content>
              <Column.Content></Column.Content>
            </Column.Root>
          )}

          {!loading && <CollaboratorsPositionsForm />}
        </Form.Root>
      </Page.Section>
    </>
  )
}
