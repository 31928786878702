import React from 'react'
import { Link } from 'react-router-dom'

export const CardButton = ({ children, className = '', colorText = null, link = null }) => {
  return (
    <Link className={`card-footer-item ${colorText} ${className}`} to={link}>
      {children}
    </Link>
  )
}
