import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { getDocument, updateDocument } from '../../../../../firebase/firestore'
import { Path } from '../../../../../router/paths'
import { TryCatch } from '../../../../../utils/general'
import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { createSchemaPlans } from '../utils'
import { ServicesOmie } from '../../../../../api/omie'
import { createSchemaPlansOmie } from '../../../../../api/omie/schemas'

export const useClientEditPlans = () => {
  const {
    // user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { id } = useParams()

  const navigate = useNavigate()

  const getForm = async () => {
    await setFormContext({})
    const plan = await getDocument('plans', id)
    await setFormContext(plan)
  }

  const editPlan = async () => {
    const idOmie = formContext?.idOmie || null

    if (!idOmie) return

    const newPlanOmie = createSchemaPlansOmie(formContext, 'edit')

    console.log(newPlanOmie)

    let editPlanOmie = false
    try {
      editPlanOmie = await ServicesOmie().AlterarServico([{ ...newPlanOmie }])
    } catch (error) {
      throw new Error(error.message)
    }

    if (!editPlanOmie) return

    const newPlano = { ...createSchemaPlans(formContext) }
    await updateDocument('plans', id, newPlano)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Plano Editado',
        msg: `O Plano "${newPlano.name}" foi editado com sucesso.`,
      },
    ])

    return navigate(Path.APlans)
  }

  const HandleEditPlan = async () => {
    await TryCatch(editPlan, setLoading, setAlerts)
  }

  const FetchGetForm = async () => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetForm()
  }, [])

  return {
    loading,
    formContext,
    setFormContext,
    HandleEditPlan,
  }
}
