import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { ClientsFormEventsJobOffersLayout } from './_form/clientsFormEventsJobOffersLayout'
import { useClientEventsjobOffers } from './_hooks/useClientEventsjobOffers'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Icon } from '../../../components/icons'
import { Table } from '../../../components/ui/table'
import { DataComTraçoParaDataComBarra } from '../../../utils/dates'
// import { Button } from '../../../components/ui/button'
// import { Path } from '../../../router/paths'

export const ClientEventsjobOffers = () => {
  const {
    idJobEdit,
    SetIdJobEdit,
    loading,
    search,
    setSearch,
    formContext,
    setFormContext,
    infosContext,
    HandleAddJobOffers,
    dataContext,
    addVagas,
    setAddVagas,
    FetchGetInfos,
    FetchGetdatas,
    HandleDelValuesFormJob,
    HandleDelJobOffers,
    HandleEditValuesFormJob,
    HandleEditJobOffers,
    HandleLockUnlockJobOffers,
  } = useClientEventsjobOffers()

  return (
    <Page.Section className="is-fullheight-with-navbar mt-6 mx-3">
      <Button.Root align="right">
        <Button.Content>
          <Button.Bt
            className=""
            color="is-dark"
            colorText="has-text-white"
            type={'button'}
            loading={loading}
            action={() => {
              FetchGetInfos()
              FetchGetdatas()
            }}
          >
            <Icon size={15}>sync</Icon>
          </Button.Bt>
        </Button.Content>
        <Button.Content>
          <Button.Bt
            className=""
            color={addVagas ? 'is-dark' : 'is-success'}
            colorText="has-text-white"
            type={'button'}
            loading={loading}
            action={() => {
              if (addVagas) {
                HandleDelValuesFormJob()
                setAddVagas((prev) => !prev)
                SetIdJobEdit(null)
              } else {
                setAddVagas((prev) => !prev)
                SetIdJobEdit(null)
              }
            }}
          >
            {addVagas ? 'x' : '+'}
          </Button.Bt>
        </Button.Content>
      </Button.Root>
      {addVagas && (
        <>
          <Column.Root>
            <Column.Content>
              <p className="subtitle is-size-4">{idJobEdit ? 'Editar Vagas' : 'Adicionar Vagas'}</p>
            </Column.Content>
          </Column.Root>
          <Form.Root
            action={() => {
              if (idJobEdit) {
                HandleEditJobOffers(idJobEdit)
                SetIdJobEdit(null)
              } else {
                HandleAddJobOffers()
              }
            }}
            className="box mb-6 mt-1"
          >
            <ClientsFormEventsJobOffersLayout
              loading={loading}
              formContext={formContext}
              setFormContext={setFormContext}
              options={infosContext?.positions || []}
              disabledList={{}}
            />

            <Column.Root className="mt-5">
              <Column.Content>
                <Button.Root align="right">
                  {!idJobEdit && (
                    <Button.Content>
                      <Button.Bt
                        className=""
                        type={'button'}
                        loading={loading}
                        action={() => {
                          HandleDelValuesFormJob()
                        }}
                      >
                        Limpar
                      </Button.Bt>
                    </Button.Content>
                  )}

                  <Button.Content>
                    <Button.Bt
                      className=""
                      color="is-success"
                      colorText="has-text-white"
                      type={'submit'}
                      loading={loading}
                    >
                      {idJobEdit ? 'Salvar' : 'Adicionar'}
                    </Button.Bt>
                  </Button.Content>
                </Button.Root>
              </Column.Content>
            </Column.Root>
          </Form.Root>
        </>
      )}

      <Column.Root>
        <Column.Content>
          <p className="subtitle is-size-4">
            <Icon size={30} className={'mr-3'}>
              job
            </Icon>
            Vagas do Evento
          </p>
        </Column.Content>
      </Column.Root>
      <Column.Root className="is-mobile">
        <Column.Content>
          <Input.Root>
            <Input.Contents alignIcon={'left'}>
              <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
              <Input.Icon align="is-left">
                <Icon size={20}>search</Icon>
              </Input.Icon>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root className="is-mobile">
        <Column.Content>
          <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
            <Table.Head>
              <Table.Row key={'tableEventsHead'}>
                <Table.Cell>Data</Table.Cell>
                <Table.Cell>Vaga</Table.Cell>
                <Table.Cell>Cache</Table.Cell>
                <Table.Cell>Quantidade</Table.Cell>
                <Table.Cell>Candidaturas</Table.Cell>
                <Table.Cell>Inicio</Table.Cell>
                <Table.Cell>Check-in</Table.Cell>
                <Table.Cell>Check-out</Table.Cell>
                <Table.Cell>Candidatura</Table.Cell>
                <Table.Cell size={200}>Ações</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {(dataContext?.jobOffers || []).map((value) => {
                return (
                  <>
                    <Table.Row key={value.id}>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {DataComTraçoParaDataComBarra(value.date)}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {infosContext?.positionsById[value.idPosition]}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered"> R$ {value.value}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{value.amountJob}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        <strong className="has-text-danger is-size-6">{value.filledJob}</strong>
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{value.hrStart}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{value.hrCheckin}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{value.hrCheckout}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        <p className={`tag ${value.status ? 'is-success' : 'is-danger'}`}>
                          {value.status ? 'Aberto' : 'Fechada'}
                        </p>
                      </Table.Cell>
                      <Table.Cell className="is-vcentered">
                        <div style={{ marginBottom: 5, marginTop: 5 }}>
                          <Button.Root align="centered">
                            <Button.Content>
                              <Button.Bt
                                color={value.status ? 'is-danger' : 'is-success'}
                                action={() => {
                                  HandleLockUnlockJobOffers(value.id, value.status)
                                }}
                                colorText={'has-text-white'}
                                loading={loading}
                                title={value.status ? 'Bloquear Candidatura' : 'Debloquear Candidatura'}
                              >
                                {value.status ? <Icon size={15}>lock</Icon> : <Icon size={15}>unlock</Icon>}
                              </Button.Bt>
                            </Button.Content>
                            <Button.Content>
                              <Button.Bt
                                color="is-light"
                                action={() => {
                                  HandleEditValuesFormJob(value.id)
                                }}
                                loading={loading}
                                title="Copiar Vaga"
                              >
                                <Icon size={15}>copy</Icon>
                              </Button.Bt>
                            </Button.Content>
                            <Button.Content>
                              <Button.Bt
                                color="is-warning"
                                action={() => {
                                  HandleEditValuesFormJob(value.id)
                                  SetIdJobEdit(value.id)
                                }}
                                loading={loading}
                              >
                                <Icon size={15}>pen</Icon>
                              </Button.Bt>
                            </Button.Content>
                            <Button.Content>
                              <Button.Bt
                                color="is-danger"
                                colorText={'has-text-white'}
                                type="button"
                                action={() => {
                                  HandleDelJobOffers(value.id)
                                }}
                                loading={loading}
                              >
                                <Icon size={15}>trash</Icon>
                              </Button.Bt>
                            </Button.Content>
                          </Button.Root>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </>
                )
              })}
            </Table.Body>
          </Table.Root>
        </Column.Content>
      </Column.Root>
    </Page.Section>
  )
}
