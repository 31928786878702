import React from 'react'

import { Page } from '../../../../components/layouts/page'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { FormPlansLayout } from './forms/formsPlansLayout'
import { useClientEditPlans } from './hooks/useEditPlans'
import { Button } from '../../../../components/ui/button'
import { Path } from '../../../../router/paths'

export const AEditPlans = () => {
  const { loading, formContext, setFormContext, HandleEditPlan } = useClientEditPlans()

  return (
    <Page.Section className="is-fullheight-with-navbar">
      <Form.Root
        action={() => {
          HandleEditPlan()
        }}
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Plano</p>
          </Column.Content>
        </Column.Root>
        <FormPlansLayout values={formContext} setValues={setFormContext} disabledList={{}} />

        <Column.Root className="mt-5 mb-6">
          <Column.Content>
            <Button.Root align="right">
              <Button.Content>
                <Button.A color="is-light" link={Path.APlans} loading={loading}>
                  Cancelar
                </Button.A>
              </Button.Content>
              <Button.Content>
                <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                  Salvar
                </Button.Bt>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
      </Form.Root>
    </Page.Section>
  )
}
