import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import {
  addSubDocument,
  deleteSubDocument,
  getDocuments,
  getSubDocuments,
  getSubDocumentsWithQuerys,
  updateArrayInDocument,
  updateSubDocument,
} from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'
import { SetHistoryEvent } from '../../../../utils/requests'

export const useClientEventsjobOffers = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { id } = useParams()

  const [search, setSearch] = useState('')
  const [addVagas, setAddVagas] = useState(false)
  const [idJobEdit, SetIdJobEdit] = useState(null)

  const getDatas = async () => {
    const getJobOffers = await getSubDocuments('events', id, 'jobOffers')

    setDataContext((prev) => ({ ...prev, jobOffers: getJobOffers }))
  }

  const getInfos = async () => {
    const getPositions = await getDocuments('cargos')
    const positionsById = getPositions.reduce((acc, curr) => {
      acc[curr.id] = curr.nome
      return acc
    }, {})
    console.log(positionsById)
    setInfosContext((prev) => ({ ...prev, positions: getPositions, positionsById }))
  }

  const addJobOffers = async () => {
    if (!user.user.permissions.jobOffers_add) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }
    const newJob = {
      idPosition: formContext?.jobName,
      value: formContext?.valueCacheJob,
      date: formContext?.date,
      hrStart: formContext?.hrStart,
      hrCheckin: formContext?.hrCheckin,
      hrCheckout: formContext?.hrCheckout,
      amountJob: Number(formContext?.amountJob),
      filledJob: 0,
      status: false,
    }

    const dateExists = (dataContext?.jobOffers || []).some((jobOffers) => jobOffers.date === newJob.date)
    const positionExists = (dataContext?.jobOffers || []).some(
      (jobOffers) => jobOffers.idPosition === newJob.idPosition,
    )

    if (dateExists && positionExists) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Vaga Já Existente',
          msg: `A vaga que está tentando adicionar já existe.`,
        },
      ])
    }

    const idJobOffers = await addSubDocument('events', id, 'jobOffers', newJob)
    const idsPosition = [newJob.idPosition]
    console.log('idsPosition: ', idsPosition)
    await updateArrayInDocument('events', id, 'idPositions', idsPosition)

    setDataContext((prev) => ({ ...prev, jobOffers: [...(prev?.jobOffers || []), { id: idJobOffers, ...newJob }] }))
    setAddVagas(false)
    await SetHistoryEvent(
      id,
      user.user.nome,
      `Vaga Criada: ${infosContext.positionsById[newJob.idPosition]} - ${newJob.date}`,
    )
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Vaga Adicionada',
        msg: `Vaga adicionada ao evento ${formContext?.name} com sucesso.`,
      },
    ])
  }

  const editJobOffers = async (idJob) => {
    if (!user.user.permissions.jobOffers_edit) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }
    const reason = window.prompt('Justifique o motivo da edição da vaga:')

    if (reason === null || reason === '') {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Justificativa obrigatória',
          msg: 'Para editar esta vaga, é necessario informar a justificativa.',
        },
      ])
    }

    const newJob = {
      idPosition: formContext?.jobName,
      value: formContext?.valueCacheJob,
      date: formContext?.date,
      hrStart: formContext?.hrStart,
      hrCheckin: formContext?.hrCheckin,
      hrCheckout: formContext?.hrCheckout,
      amountJob: formContext?.amountJob,
    }

    await updateSubDocument('events', id, 'jobOffers', idJob, newJob)
    await updateArrayInDocument('events', id, 'idPositions', [newJob.idPosition])

    const newJobsData = (dataContext?.jobOffers || []).filter((job) => job.id !== idJob)
    console.log(newJobsData)
    deleteValuesFormJob()
    setAddVagas(false)
    await FetchGetInfos()
    await FetchGetdatas()
    await SetHistoryEvent(
      id,
      user.user.nome,
      `Vaga Edita: ${infosContext.positionsById[newJob.idPosition]} - ${newJob.date} ${reason !== '' && `|| Motivo: ${reason}`}`,
    )

    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Vaga Editada',
        msg: `Vaga Editada com sucesso.`,
      },
    ])
  }

  const deleteJobOffers = async (idJob) => {
    if (!user.user.permissions.jobOffers_delete) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }
    const reason = window.prompt('Informe a justificativa da Exclusão dessa vaga: ')

    if (reason === null || reason === '') {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Justificativa obrigatória',
          msg: `Para Deletar essa vaga, é necessario informar a justificativa.`,
        },
      ])
    }

    const colabsCandidateInJobOffers = await getSubDocumentsWithQuerys(
      'events',
      id,
      'staff',
      [
        { field: 'idJobOffers', operator: '==', value: idJob },
        { field: 'status', operator: '!=', value: 11 },
      ],
      1,
    )

    if (Object.keys(colabsCandidateInJobOffers || {}).length > 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Vaga com Colaboradores',
          msg: `Não é possível deletar esta vaga, pois existem colaboradores cadastrados nela. Por favor, cancele as candidaturas antes de tentar deletar.`,
        },
      ])
    }

    const job = (dataContext?.jobOffers || []).filter((jobOffers) => jobOffers.id === idJob)[0]

    await deleteSubDocument('events', id, 'jobOffers', idJob)
    console.log([job.idPosition])
    await updateArrayInDocument('events', id, 'idPositions', [job.idPosition], false)

    await setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Vaga Deletada',
        msg: `A Vaga ${infosContext.positionsById[job.idPosition]} do dia ${job.date} foi deletada do evento ${formContext?.name} com sucesso.`,
      },
    ])

    await FetchGetInfos()
    await FetchGetdatas()
    await SetHistoryEvent(
      id,
      user.user.nome,
      `Vaga Deletada: ${infosContext.positionsById[job.idPosition]} - ${job.date} ${reason !== '' && `|| Motivo: ${reason}`}`,
    )
  }

  const lockUnlockJobOffers = async (idJob, status) => {
    if (!user.user.permissions.jobOffers_openClose) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }

    const job = (dataContext?.jobOffers || []).filter((jobOffers) => jobOffers.id === idJob)[0]

    const newJob = { status: !status }

    await updateSubDocument('events', id, 'jobOffers', idJob, newJob)

    await setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: status ? 'Candidatura Bloqueada' : 'Candidatura Liberada',
        msg: status
          ? `A Vaga ${infosContext.positionsById[job.idPosition]} do dia ${job.date} esta fechada para candidatura.`
          : `A Vaga ${infosContext.positionsById[job.idPosition]} do dia ${job.date} esta aberta para candidatura.`,
      },
    ])

    await FetchGetInfos()
    await FetchGetdatas()
    await SetHistoryEvent(
      id,
      user.user.nome,
      status
        ? `Candidatura Bloqueada: ${infosContext.positionsById[job.idPosition]} - ${job.date}`
        : `Candidatura Desbloqueada: ${infosContext.positionsById[job.idPosition]} - ${job.date}`,
    )
  }

  const deleteValuesFormJob = async () => {
    const newJob = {
      jobName: '',
      valueCacheJob: '',
      date: '',
      hrStart: '',
      hrCheckin: '',
      hrCheckout: '',
      amountJob: '',
    }

    setFormContext((prev) => ({ ...prev, ...newJob }))
  }

  const editValuesFormJob = async (idJob) => {
    const job = (dataContext?.jobOffers || []).filter((jobOffers) => jobOffers.id === idJob)[0]

    const newJob = {
      jobName: job.idPosition,
      valueCacheJob: job.value,
      date: job.date,
      hrStart: job.hrStart,
      hrCheckin: job.hrCheckin,
      hrCheckout: job.hrCheckout,
      amountJob: job.amountJob,
    }

    setFormContext((prev) => ({ ...prev, ...newJob }))
    setAddVagas(true)
  }

  const HandleAddJobOffers = async () => {
    await TryCatch(
      () => {
        addJobOffers()
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleDelJobOffers = async (idJob) => {
    await TryCatch(
      () => {
        deleteJobOffers(idJob)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleEditJobOffers = async (idJob) => {
    await TryCatch(
      () => {
        editJobOffers(idJob)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleLockUnlockJobOffers = async (idJob, status) => {
    await TryCatch(
      () => {
        lockUnlockJobOffers(idJob, status)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleEditValuesFormJob = async (idJob) => {
    await TryCatch(
      () => {
        editValuesFormJob(idJob)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleDelValuesFormJob = async () => {
    await TryCatch(deleteValuesFormJob, setLoading, setAlerts)
  }

  const FetchGetdatas = useCallback(async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }, [])

  const FetchGetInfos = useCallback(async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await FetchGetInfos()
      await FetchGetdatas()
    }
    fetchData()
  }, [formContext.openAllJobs, FetchGetInfos, FetchGetdatas])

  return {
    id,
    idJobEdit,
    SetIdJobEdit,
    loading,
    search,
    setSearch,
    formContext,
    setFormContext,
    HandleAddJobOffers,
    FetchGetInfos,
    FetchGetdatas,
    infosContext,
    setInfosContext,
    dataContext,
    addVagas,
    setAddVagas,
    HandleDelValuesFormJob,
    HandleDelJobOffers,
    HandleEditValuesFormJob,
    HandleEditJobOffers,
    HandleLockUnlockJobOffers,
  }
}
