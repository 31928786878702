import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
// import { useAdminClients } from './useAdminClients'
import { DateTimeNow } from '../../../../utils/dates'
import {
  addSubDocument,
  deleteSubCollection,
  getDocument,
  getSubDocument,
  getSubDocuments,
  setSubDocument,
  updateDocument,
} from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'
import { GetUserAuthenticatorFromEmail } from '../../../../firebase/functions'
import { NewUser } from '../../../../utils/requests'
import { resetPassword } from '../../../../firebase/auth'
import { createSchemaClient, createSchemaClientPlans, GetStatusClient } from '../_utils'
import { ClientOmie } from '../../../../api/omie'
import { createSchemaClientOmie } from '../../../../api/omie/schemas'

export const useEditClients = () => {
  const { id } = useParams()
  const { setAlerts, user, loading, setLoading, setFormContext, formContext, infosContext } = useGlobalContext()

  // const { FetchClientInfos } = useAdminClients()

  const navigate = useNavigate()

  const status = GetStatusClient(formContext?.status).value

  const getForm = async () => {
    // Obtém informações do cliente e assinaturas
    const [getClient, getPlans] = await Promise.all([
      getDocument('clientes', id),
      getSubDocuments('clientes', id, 'plans'),
    ])

    // Verifica se o e-mail do responsável está presente
    const email = getClient?.responsavel?.email
    if (email) {
      // Obtém o UID do responsável e adiciona à informação do responsável
      const uid = await GetUserAuthenticatorFromEmail(email)
      console.log(uid)
      try {
        const infoUserAdmin = await getSubDocument('clientes', id, 'users', uid.uid)
        getClient.userAdmin = {
          ...infoUserAdmin,
        }
        console.log(infoUserAdmin)
      } catch (error) {}
    }

    const client = { ...getClient, plans: getPlans }
    console.log(client)
    // Atualiza o contexto do formulário com os dados do cliente
    await setFormContext((prev) => ({ ...prev, ...client }))
  }

  const editClient = async () => {
    // console.log(user.user)

    if (!user.user.permissions.clientes_editar) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para editar clientes',
        },
      ])
    }

    if (!formContext?.idClientOmie) return

    const newClientOmie = {
      ...createSchemaClientOmie(formContext),
    }

    try {
      await ClientOmie().alterarCliente([{ ...newClientOmie }])
    } catch (error) {
      throw new Error(error.message)
    }

    const newClient = {
      ...createSchemaClient(formContext),
    }

    await updateDocument('clientes', id, newClient)

    await deleteSubCollection('clientes', id, 'plans')

    const promises = formContext.plans.map(async (plan) => {
      const newPlan = createSchemaClientPlans({ ...plan, modifieldBy: user.user.nome })
      console.log(newPlan)
      await addSubDocument('clientes', id, 'plans', newPlan)
    })

    Promise.all(promises).finally((response) => {
      console.log(response)
      return window.location.reload()
    })
  }

  const PrintPage = () => {
    if (!user?.user?.permissions?.clientes_imprimir === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você Não Possui permissão para imprimir os dados do cliente',
          type: 'error',
        },
      ])
    }

    window.print()
  }

  const ResetPasswordAdmin = async () => {
    if (!user?.user?.permissions?.clientes_resetarSenhaAdmin === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para resetar a senha de administrador do cliente',
          type: 'error',
        },
      ])
    }

    const sendEmailReset = await resetPassword(formContext?.responsavel?.email)
    console.log('sendEmailReset:', sendEmailReset)
    if (sendEmailReset.success) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Resete de Senha Enviado',
          msg: `Um E-mail para trocar senha foi enviado ao e-mail do responsavel: ${formContext?.responsavel?.email}`,
          type: 'success',
        },
      ])
    } else {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Erro ao enviar E-mail',
          msg: `erro: ${sendEmailReset?.error}`,
          type: 'error',
        },
      ])
    }
  }

  const addUserAdmin = async () => {
    if (!user?.user?.permissions?.clientes_criarUsuarioAdmin === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para Criar um usuário administrador para o cliente',
          type: 'error',
        },
      ])
    }

    let permissionsAdmins
    let SectorId
    try {
      permissionsAdmins = await getSubDocument('clientes', id, 'sectors', 'admin')
      SectorId = permissionsAdmins?.id
    } catch (error) {
      console.log('Não achou o setor, vai criar um novo')
    }

    if (!SectorId) {
      const clientsPermissions = await getDocument('configs', 'clients_permissions')

      const permissions = Object.keys(clientsPermissions)
        .filter((permission) => permission !== 'id')
        .reduce((acc, permission) => {
          Object.keys(clientsPermissions?.[permission] || {}).forEach((key) => {
            acc[`${permission}_${key}`] = clientsPermissions[permission][key]
          })
          return acc
        }, {})

      permissionsAdmins = {
        nome: 'Admin',
        description: 'Permissões padrões de Administrador',
        permissions,
      }

      SectorId = await setSubDocument('clientes', id, 'sectors', 'admin', permissionsAdmins)
    }

    const userPrincipalInfos = {
      nome: formContext?.reponsavel_name,
      cargo: 'Administrador',
      email: formContext?.responsavel_email,
      cpf: formContext?.responsavel_cpf,
      url_foto: '',
      sectorId: 'admin',
      createBy: user.user.nome,
      dtCreated: DateTimeNow(),
      status: true,
      type: 'client',
    }
    await NewUser(userPrincipalInfos, id, setAlerts)
    return window.location.reload()
  }

  const lockAndUnlockClient = async () => {
    if (!user?.user?.permissions?.clientes_bloquearDesbloquear === true) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para Bloquear/Desbloquear o cliente',
          type: 'error',
        },
      ])
    }
    const getInfosCurrent = await getDocument('clientes', id)
    const status =
      getInfosCurrent?.status === 'deleted' || getInfosCurrent?.status === 'disabled' ? 'active' : 'disabled'

    const statusOmie = getInfosCurrent?.status === 'deleted' || getInfosCurrent?.status === 'disabled' ? 'N' : 'S'
    console.log('status: ', status)
    console.log('statusOmie: ', statusOmie)
    let alterarClienteOmie = false

    const newclientOmie = {
      codigo_cliente_omie: getInfosCurrent.idClientOmie,
      codigo_cliente_integracao: getInfosCurrent.id,
      inativo: statusOmie,
    }
    console.log('newclientOmie: ', newclientOmie)
    try {
      alterarClienteOmie = await ClientOmie().alterarCliente([newclientOmie])
    } catch (error) {
      throw new Error(error.message)
    }

    if (!alterarClienteOmie) return

    await updateDocument('clientes', id, { status })
    return window.location.reload()
  }

  const FetchLockAndUnlockClient = () => {
    TryCatch(lockAndUnlockClient, setLoading, setAlerts)
  }

  const FetchGetForm = () => {
    TryCatch(getForm, setLoading, setAlerts)
  }

  const HandleEditClient = () => {
    TryCatch(editClient, setLoading, setAlerts)
  }

  const FetchPrintPage = () => {
    TryCatch(PrintPage, setLoading, setAlerts)
  }

  const FetchResetPasswordAdmin = () => {
    TryCatch(ResetPasswordAdmin, setLoading, setAlerts)
  }

  const FetchaddUserAdmin = () => {
    TryCatch(addUserAdmin, setLoading, setAlerts)
  }

  useEffect(() => {
    // FetchClientInfos()
    FetchGetForm()
  }, [])

  return {
    id,
    status,
    navigate,
    infosContext,
    loading,
    setLoading,
    setFormContext,
    formContext,
    HandleEditClient,
    FetchPrintPage,
    FetchResetPasswordAdmin,
    FetchaddUserAdmin,
    FetchLockAndUnlockClient,
  }
}
