import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { getDocument, getDocuments, updateDocument } from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'

export const useAdminCollaboratorPositions = () => {
  const {
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { id } = useParams()

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getConsents = await getDocuments('consentimentos')
    const getDocs = await getDocuments('documentos')
    const getPositions = await getDocuments('cargos')

    const formattedConsents = getConsents.map(({ id, nome: name }) => {
      return {
        id,
        name,
      }
    })

    const formattedDocs = getDocs.map(({ id, nome: name }) => {
      return {
        id,
        name,
      }
    })
    const formattedPositions = getPositions.map(({ id, nome: name, consentimentos, documentos }) => {
      return {
        id,
        name,
        consentimentos,
        documentos,
      }
    })

    setInfosContext({
      consentimentos: formattedConsents,
      documentos: formattedDocs,
      cargos: formattedPositions,
    })
  }

  const getDatas = async () => {
    const collaborator = await getDocument('colaboradores', id)
    const collaboratorFormData = {
      consentimentos: collaborator?.consents,
      documentos: collaborator?.documents,
      cargos: collaborator?.positions,
    }

    setFormContext(collaboratorFormData)
  }

  const editCollaborator = async () => {
    const editedCollaborator = {
      consents: { ...formContext?.consentimentos },
      documents: { ...formContext?.documentos },
      positions: { ...formContext?.cargos },
    }

    await updateDocument('colaboradores', id, editedCollaborator)

    // setAlerts((prev) => [
    //   ...prev,
    //   {
    //     type: 'success',
    //     title: 'Colaborador editado',
    //     msg: `O colaborador ${editedCollaborator.name} foi editado com sucesso.`,
    //   },
    // ])
  }

  const handleEditPositions = async () => {
    await TryCatch(editCollaborator, setLoading, setAlerts)
  }

  const FetchDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const FetchInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchDatas()
    FetchInfos()
  }, [])

  return {
    loading,
    handleEditPositions,
  }
}
