export function isValidCPF(cpf) {
  // Remove non-numeric characters
  cpf = cpf.replace(/[^\d]/g, '')

  // Check if CPF has 11 digits and is not a repeated sequence
  if (!isValidCPFFormat(cpf)) {
    return false
  }

  // Validate first and second check digits
  const firstCheckDigit = calculateCPFCheckDigit(cpf, 10)
  const secondCheckDigit = calculateCPFCheckDigit(cpf, 11)

  return cpf.endsWith(`${firstCheckDigit}${secondCheckDigit}`)
}

function isValidCPFFormat(cpf) {
  return cpf.length === 11 && !/^(\d)\1+$/.test(cpf) // Ensure 11 digits and not a repeated sequence
}

function calculateCPFCheckDigit(cpf, factor) {
  let sum = 0

  for (let i = 0; i < factor - 1; i++) {
    sum += parseInt(cpf[i]) * (factor - i)
  }

  const remainder = sum % 11
  return remainder < 2 ? 0 : 11 - remainder
}
