import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
// import { Button } from '../../../components/ui/button'
import { Icon } from '../../../components/icons'
import { Table } from '../../../components/ui/table'
import { Input } from '../../../components/ui/input'
import { Button } from '../../../components/ui/button'
import { usePaymentsClients } from './_hooks/usePaymentClients'
import { colorTagStatusPagamentos } from './_utils'

export const AdminClientsPayments = () => {
  const { loading, search, setSearch, dataFiltered, FetchGetData, HandleCancelBilling } = usePaymentsClients()

  return (
    <Page.Section className="is-fullheight-with-navbar  mt-6 pt-6">
      <Column.Root>
        <Column.Content>
          <p className="subtitle is-size-3">Pagamentos</p>
        </Column.Content>
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.Bt
                color="is-dark"
                colorText="has-text-white"
                action={() => {
                  FetchGetData()
                }}
                loading={loading}
              >
                <Icon size={15}>sync</Icon>
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>

      {/* Barra de pesquisa */}
      <Column.Root className="is-mobile">
        <Column.Content>
          <Input.Root>
            <Input.Contents alignIcon={'left'}>
              <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
              <Input.Icon align="is-left">
                <Icon size={20}>search</Icon>
              </Input.Icon>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>
      {/* Tabela de dados */}
      <Column.Root className="is-mobile">
        <Column.Content>
          <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
            <Table.Head>
              <Table.Row key={'table_payments_head'}>
                <Table.Cell>Nº Doc</Table.Cell>
                <Table.Cell>Lancamento</Table.Cell>
                <Table.Cell>Vencimento</Table.Cell>
                <Table.Cell>Parcelas</Table.Cell>
                <Table.Cell>Tipo</Table.Cell>
                <Table.Cell>Valor</Table.Cell>
                <Table.Cell>Situação</Table.Cell>
                <Table.Cell>Pago em</Table.Cell>
                <Table.Cell>Ações</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {dataFiltered.map((value) => {
                return (
                  <>
                    <Table.Row key={value.id}>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {value.numero_pedido || value.numero_documento}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{value.codigo_lancamento_omie}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {String(value.dt_vencimento || '-').split('T')[0]}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{value.numero_parcela}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {value.type === 'BOL' ? 'Boleto' : 'Pix'}
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">R$ {value.value}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        <p className={`tag ${colorTagStatusPagamentos(value.status)}`}>{value.status}</p>
                      </Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">
                        {String(value.data_cred || '-').split('T')[0]}
                      </Table.Cell>
                      <Table.Cell className="is-vcentered" style={{ height: 50 }}>
                        <div style={{ marginBottom: -5, marginTop: 5 }}>
                          <Button.Root align="centered">
                            {(value?.cUrlNfse || value?.cUrlPdfDemo) && (
                              <Button.Content>
                                <Button.A
                                  className={'mx-2'}
                                  color="is-warning"
                                  link={value.cUrlNfse !== '' ? value.cUrlNfse : value.cUrlPdfDemo}
                                  loading={loading}
                                >
                                  <Icon size={15}>print</Icon>
                                </Button.A>
                              </Button.Content>
                            )}
                            {value?.status === 'successBilling' && (
                              <Button.Content>
                                <Button.Bt
                                  className={'mx-2'}
                                  color="is-danger"
                                  loading={loading}
                                  title="Cancelar Faturamento"
                                  action={async () => {
                                    await HandleCancelBilling(value)
                                  }}
                                >
                                  <Icon size={15}>trash</Icon>
                                </Button.Bt>
                              </Button.Content>
                            )}
                          </Button.Root>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </>
                )
              })}
            </Table.Body>
          </Table.Root>
        </Column.Content>
      </Column.Root>
    </Page.Section>
  )
}
