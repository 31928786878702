import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import {
  addSubDocument,
  deleteSubCollection,
  // addSubDocument,
  // deleteSubCollection,
  // // addSubDocument,
  // // deleteSubCollection,
  getDocuments,
  getSubDocuments,
  // updateDocument,
} from '../../../../firebase/firestore'
import { createSchemaClientPlans } from '../../clients/_utils'
// import { createSchemaClientPlans } from '../../clients/_utils'
// import { createSchemaClientPlans } from '../../clients/_utils'
export const useCollaboratorPayments = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const paymentsDatas = dataContext?.payments || {}
  const [search, setSearch] = useState('')

  const clientId = user.client.id
  const { id } = useParams()

  const getDatas = async () => {
    const getPayments = await getSubDocuments('clientes', clientId, 'payments')
    const getPlans = formContext?.plans || (await getSubDocuments('colaboradores', id, 'plans'))
    // salva todas as informações coletadas no context.
    setDataContext({ payments: getPayments })
    setFormContext({ plans: getPlans })
  }

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getPlans = infosContext?.plans || (await getDocuments('plans'))

    setInfosContext({
      plans: getPlans,
    })
  }

  console.log(infosContext.plans)

  const editPayment = async () => {
    console.log(user.user)

    if (!user.user.permissions.clientes_editar) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Sem Permissão',
          msg: 'Você não possui permissão para editar clientes',
        },
      ])
    }

    // if (!formContext?.idClientOmie) return

    // const newClientOmie = {
    //   ...createSchemaClientOmie(formContext),
    // }

    // try {
    //   await ClientOmie().alterarCliente([{ ...newClientOmie }])
    // } catch (error) {
    //   throw new Error(error.message)
    // }

    // const newClient = {
    //   ...createSchemaClient(formContext),
    // }

    // await updateDocument('clientes', id, newClient)

    await deleteSubCollection('colaboradores', id, 'plans')

    const promises = formContext.plans.map(async (plan) => {
      const newPlan = createSchemaClientPlans({ ...plan, modifieldBy: user.user.nome })
      console.log('plan', plan)
      await addSubDocument('colaboradores', id, 'plans', newPlan)
    })

    Promise.all(promises).finally((response) => {
      console.log(response)
      // return window.location.reload()
    })
  }

  const AddPlanInList = (idPlan) => {
    if ((formContext?.plans || []).some((item) => item.id === idPlan)) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Plano já adicionado',
          msg: 'Somente é permitido inserir planos diferente',
          type: 'error',
        },
      ])
    }

    if ((formContext?.plans || []).some((item) => !item?.dtStart || !item?.dtEnd)) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Datas Vazias',
          msg: 'Antes de adicionar um novo plano, porfavor, preencha as datas de inicio do plano que esta na lista',
          type: 'error',
        },
      ])
    }

    const plan = (infosContext?.plans || []).filter((item) => item.id === idPlan)[0]

    setFormContext((prev) => {
      const index = (prev.plans || []).length
      let newPlan = { ...plan, index }
      if (index > 0) {
        const maxDate = getMaxDate(prev.plans || [], 'dtEnd')
        const dtStart = sumDate(maxDate, 1)
        const dtEnd = sumDate(dtStart, Number(plan.type_expired))
        newPlan = { ...newPlan, dtStart, dtEnd }
      }
      return {
        ...prev,
        plans: [...(prev.plans || []), newPlan],
      }
    })
  }

  const DeletePlansOfList = (idPlan) => {
    setFormContext((prev) => {
      const newPlans = (prev.plans || [])
        .map((item, index) => {
          if (item.id !== idPlan) {
            return { ...item, index }
          }
          return null
        })
        .filter((item) => item !== null)
      return {
        ...prev,
        plans: newPlans,
      }
    })
  }

  const RemoveEndDate = (idPlan) => {
    setFormContext((prev) => {
      const newPlans = (prev.plans || []).map((item) => {
        if (idPlan === item.id) {
          delete item.dtEnd
        }
        return item
      })
      return {
        ...prev,
        plans: newPlans,
      }
    })
  }

  const AddEndDate = (idPlan) => {
    setFormContext((prev) => {
      const newPlans = (prev.plans || []).map((item) => {
        if (idPlan === item.id) {
          const lastEndDate = Number(item.typeExpired) > 0 ? sumDate(item.dtStart, Number(item.typeExpired)) : null
          if (lastEndDate !== null) {
            item.dtEnd = Number(item.typeExpired) > 0 ? sumDate(item.dtStart, Number(item.typeExpired)) : null
          }
        }
        return item
      })
      return {
        ...prev,
        plans: newPlans,
      }
    })
  }

  const getMaxDate = (data, field) => {
    return data.reduce((max, item) => {
      return new Date(item.dtstart) > new Date(max) ? item[field] : max
    }, data[0][field])
  }

  const sumDate = (date, days) => {
    const novaData = new Date(date) // Converte string para objeto Date
    novaData.setDate(novaData.getDate() + days) // Adiciona os dias
    return novaData.toISOString().split('T')[0] // Formata de volta para 'YYYY-MM-DD'
  }

  const setDtStart = (idPlan, value) => {
    console.log(idPlan)
    console.log(value)
    const listplans = formContext.plans
    const newPlan = listplans.filter((item) => item.id === idPlan)[0]

    // Localiza a última data anterior ao índice do plano atual
    const previousDate = listplans.reduce((max, item) => {
      if (item.index < newPlan.index) {
        const itemStartDate = new Date(item.dtEnd)
        return itemStartDate > new Date(max) ? item.dtEnd : max
      }
      return max
    }, '1970-01-01') // Valor inicial neutro para comparação de datas

    console.log(previousDate)
    // Verifica se a data é válida
    if (new Date(value) < new Date(previousDate)) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Data maior',
          msg: 'Não é possível selecionar uma data menor que a última data do plano anterior.',
        },
      ])
    }

    console.log(newPlan)
    setFormContext((prev) => {
      let updateLastValues = false
      let lastEndDate
      const newPlans = (prev.plans || []).map((item, index) => {
        if (item.id === idPlan) {
          // Atualiza o plano atual
          updateLastValues = true
          lastEndDate = Number(item.typeExpired) > 0 ? sumDate(value, Number(item.typeExpired)) : null
          return { ...item, dtStart: value, ...(lastEndDate !== null && { dtEnd: lastEndDate }) }
          // Calcula o dtEnd do plano atual
        }

        if (updateLastValues) {
          // Atualiza os próximos planos
          if (lastEndDate) {
            const newStartDate = sumDate(lastEndDate, 1) // Adiciona 1 dia ao dtEnd anterior
            lastEndDate = Number(item.typeExpired) > 0 ? sumDate(newStartDate, Number(item.typeExpired)) : null
            return { ...item, dtStart: newStartDate, ...(lastEndDate !== null && { dtEnd: lastEndDate }) }
          }
        }

        // Retorna o plano inalterado
        return item
      })

      return { ...prev, plans: newPlans } // Atualiza o estado com os novos planos
    })
  }

  const HandleEditClient = async () => {
    await TryCatch(editPayment, setLoading, setAlerts)
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const dataFiltered =
    search !== ''
      ? Object.values(paymentsDatas).filter((pay) => {
          const searchString =
            `${pay.data}|${pay.description}|${pay.value}|${pay.status ? 'pago' : 'pendente'}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : Object.values(paymentsDatas)

  useEffect(() => {
    FetchGetDatas()
    FetchGetInfos()
  }, [])

  return {
    loading,
    search,
    setSearch,
    dataFiltered,
    // assinaturasDatas,
    dataContext,
    setDataContext,
    formContext,
    setFormContext,
    infosContext,
    FetchGetDatas,
    FetchGetInfos,
    AddPlanInList,
    DeletePlansOfList,
    RemoveEndDate,
    setDtStart,
    AddEndDate,
    HandleEditClient,
  }
}
