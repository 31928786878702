import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { increment } from 'firebase/firestore'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import { FilteredQuery } from '../_utils/filteredQuery'
import { GetPaginationStaff } from '../../../../firebase/functions'
import {
  addSubDocument,
  getDocument,
  getDocuments,
  getSubDocument,
  getSubDocuments,
  setSubDocument,
  updateDocument,
  updateSubDocument,
} from '../../../../firebase/firestore'
import { DeleteValueInArrayOfObject } from '../../../../utils/handlingArrays'
import { DateNow, DateTimeNow } from '../../../../utils/dates'
import { CreateSearchField } from '../../../../utils/createSeachField'
import { ExportAllStaffPayment } from '../../../../utils/exportToExcel'
import { SetHistoryEvent } from '../../../../utils/requests'

export const useClientEventsFinally = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
    statusLoading,
    setStatusLoading,
  } = useGlobalContext()

  const { id } = useParams()

  const navigate = useNavigate()

  const [page, setPage] = useState(0)

  const [filter, setFilter] = useState({ date: 'todos', idPosition: 'todos', status: 'todos' })

  const [modalSelectCandidacy, setModalSelectCandidacy] = useState(false)

  // const navigate = useNavigate();
  const clientId = user.client.id

  const getDatas = async ({ query = null, pageQuery = null } = {}) => {
    await setDataContext({})

    const newPage = pageQuery || query?.page || 1

    const getStaff = await GetPaginationStaff(newPage, 20, id, query, { field: 'status', direction: 'asc' })

    setDataContext(getStaff)

    return getStaff
  }

  const queryData = async () => {
    const type = formContext?.typeSelectCandidacy
    const date = filter?.date === 'todos' ? null : filter?.date
    const position = filter?.idPosition === 'todos' ? null : filter?.idPosition
    let status = filter?.status === 'todos' ? null : Number(filter?.status)

    // Desabilita consultar todos para ajustes/descontos e favoritar pessoas
    if (type === 'debts' || type === 'favorite') {
      if (status === null) {
        status = [7, 9, 10]
      } else if (status !== 7 && status !== 9 && status !== 10) {
        status = 7
        setFilter((prev) => ({ ...prev, status: 7 }))
        setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Pesquisa somente check-out realizados',
            msg: 'Não é possível pesquisar candidaturas que ainda não completaram o processo de check-out',
          },
        ])
      }
    }
    if (type === 'blocked') {
      if (status === null) {
        status = [0, 1, 2, 3, 4, 5, 6, 8, 11]
      } else if (status === 7 || status === 9 || status === 10) {
        status = [0, 1, 2, 3, 4, 5, 6, 8, 11]
        setFilter((prev) => ({ ...prev, status: 'todos' }))
        setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Bloqueio de pesquisa',
            msg: 'Não é possível bloquear candidaturas que tenham completaram o processo de check-out',
          },
        ])
      }
    }

    const search =
      filter?.search === '' || filter?.search === undefined || filter?.search === null || isNaN(filter?.search)
        ? null
        : filter?.search

    const datesByQuery = new Set() // Usando Set para evitar duplicatas
    const positionsByQuery = new Set() // Usando Set para evitar duplicatas

    // coleta os idJoboffers que contem as datas e cagas do filtro
    Object.values(infosContext?.jobOffers).forEach((jobOffer) => {
      if (date && jobOffer.date === date) {
        datesByQuery.add(jobOffer.id)
      }
      if (position && jobOffer.idPosition === position) {
        positionsByQuery.add(jobOffer.id)
      }
    })

    let idJobOffers = []

    // Verifica se ambos os conjuntos estão vazios
    if (datesByQuery.size === 0 && positionsByQuery.size === 0) {
      idJobOffers = [] // Retorna um array vazio
    } else if (datesByQuery.size === 0) {
      idJobOffers = Array.from(positionsByQuery) // Retorna positionsByQuery se datesByQuery estiver vazio
    } else if (positionsByQuery.size === 0) {
      idJobOffers = Array.from(datesByQuery) // Retorna datesByQuery se positionsByQuery estiver vazio
    } else {
      // Se ambos os conjuntos não estão vazios, filtra os elementos iguais
      idJobOffers = Array.from(datesByQuery).filter((value) => positionsByQuery.has(value))
    }

    const query = {
      ...filter,
      search,
      status,
      idJobOffers,
    }
    console.log(query)
    const moreQuery = { date, idPosition: position, page: 1 }
    delete query.filtered
    const filteredQuery = FilteredQuery({ ...query, ...moreQuery })
    await getDatas({ query: filteredQuery, pageQuery: 1 })
  }

  const getForm = async () => {
    let finallyEvent = await getSubDocuments('events', id, 'finally')
    finallyEvent = finallyEvent.filter((infos) => infos.id === 'infos')
    if (finallyEvent.length === 0) return
    finallyEvent = finallyEvent[0]
    delete finallyEvent.id
    console.log(finallyEvent)
    setFormContext((prev) => ({ ...prev, ...finallyEvent }))
    setStatusLoading('')
  }

  const getInfos = async () => {
    const getJobOffers = await getSubDocuments('events', id, 'jobOffers')
    const getPositions = await getDocuments('cargos')
    const positionsById = getPositions.reduce((acc, curr) => {
      acc[curr.id] = curr.nome
      return acc
    }, {})
    const JobOffersById = getJobOffers.reduce((acc, curr) => {
      acc[curr.id] = { ...curr, positionName: positionsById[curr.idPosition] }
      return acc
    }, {})

    const dates = []
    const positions = []

    const getTeams = await getSubDocuments('clientes', clientId, 'teams')

    Object.values(JobOffersById).forEach((offer) => {
      if (!dates.includes(offer.date)) {
        dates.push(offer.date)
      }
      if (!positions.includes(offer.idPosition)) {
        positions.push(offer.idPosition)
      }
    })

    const filters = {
      dates,
      positions,
    }

    const newInfos = {
      positions: getPositions,
      positionsById,
      jobOffers: JobOffersById,
      filters,
      teams: getTeams,
    }

    console.log(newInfos)

    setInfosContext((prev) => ({ ...prev, ...newInfos }))
  }

  const schemafinallyEvent = async (value) => {
    return {
      list_debts: value?.list_debts || [],
      list_favorite: value?.list_favorite || [],
      list_blocked: value?.list_blocked || [],
      favoriteAll: value.favoriteAll || false,
      createNewTeams: value.createNewTeams || false,
      hasFavorites: value.hasFavorites || false,
      hasDebts: value.hasDebts || false,
      hasBlocked: value.hasBlocked || false,
      motivoDefaultBlocked: value?.motivoDefaultBlocked || '',
      nameTeams: value?.nameTeams || '',
    }
  }

  const saveFinnalyInfoEvent = async () => {
    if (!user.user.permissions.finallyEvent_save) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }

    const newFinallyInfo = await schemafinallyEvent(formContext)
    console.log(newFinallyInfo)
    await setSubDocument('events', id, 'finally', 'infos', newFinallyInfo)

    await SetHistoryEvent(id, user.user.nome, `Informações de finalização do evento salvas`)

    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Informações Salvas',
        msg: 'As informações de finalziação foram salvas com sucesso na base de dados.',
      },
    ])
  }

  const finnalyStaff = async (hasDebts, getStaff, newFinallyInfo) => {
    try {
      const roleStatusFinally = {
        0: {
          status: 1,
          reason: 'Sua candidatura não foi selecionada para este evento',
        }, // Em aprovação >>> Candidatura Recusada
        2: {
          status: 4,
          reason: 'Você não confirmou a presença no evento e perdeu 1 ponto no score',
          score: -1,
        }, // Aguardando confirmação >>> Presença recusada
        3: {
          status: 6,
          reason: 'Você confirmou presença, mas não compareceu ao evento e perdeu 2 pontos no score',
          score: -2,
        }, // Presença Confirmada >>> Falta no Evento
        5: {
          status: 8,
        }, // Check-in Realizado >>> Checkout Não Realizado
        7: {
          status: 9,
          score: +2,
        }, // Checkout Realizado >>> Pagamento Pendente
      }
      // coleta todo o staff

      // Coleta pessoa com checkin realizado e pois o checkout não foi realizado
      const checkoutOpen = getStaff.filter((candidacy) => candidacy.status === 5 || candidacy.status === 8)
      if (checkoutOpen.length > 0) {
        return setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Checkouts não Realizados',
            msg: 'Alguns colaboradores ainda não realizaram o checkout no evento. Por favor, verifique, conclua o checkout e tente novamente.',
          },
        ])
      }

      const promissesAdjustCandacys = getStaff.map(async (candidacy) => {
        const newStatus = roleStatusFinally[Number(candidacy?.status)] || {}
        let financialHistory = []
        const score = newStatus?.score || null
        if (hasDebts) {
          const listDebt = newFinallyInfo?.list_debts || []
          financialHistory = listDebt
            .filter((infos) => infos.id === candidacy.id)
            .map((financial) => {
              return {
                value: financial.valueDebt,
                desc: financial.motivoDebt,
                type: financial.typeDebt,
              }
            })
        }
        // reason == motivo
        const newCandidacy = {
          status: newStatus.status || candidacy.status,
          ...(newStatus?.reason && { reason: newStatus.reason || '' }),
          financialHistory,
        }

        console.log(newCandidacy)

        await updateSubDocument('events', id, 'staff', candidacy.id, newCandidacy)
        if (score) {
          const jobOffer = await getSubDocument('events', id, 'jobOffers', candidacy.idJobOffers)
          await updateDocument('colaboradores', candidacy?.uuid, {
            [`cargos.${jobOffer.idPosition}.score`]: increment(Number(score)),
          })
        }
      })

      await Promise.all(promissesAdjustCandacys)

      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Staff Ajustada',
          msg: 'Todas as informações que ajuste e desconto, foram registrados nas cnadidaturas, com sucesso...',
        },
      ])
      return true
    } catch (e) {
      return false
    }
  }

  const favoriteStaff = async (hasFavorite, getStaff, eventInfos, newFinallyInfo) => {
    try {
      if (!hasFavorite) return true
      const favoriteAll = newFinallyInfo?.favoriteAll
      const createNewTeams = newFinallyInfo?.createNewTeams
      const nameTeams = newFinallyInfo?.nameTeams

      console.log(createNewTeams)
      let promisseFavoriteAll
      const newTeam = []

      if (favoriteAll) {
        const processedUuids = new Set() // Usa Set para evitar duplicação

        const promiseFavoriteAll = getStaff.map(async (candidacy) => {
          const { uuid } = candidacy

          // Verifica e pula se o UUID já foi processado
          if (processedUuids.has(uuid)) return

          // Marca o UUID como processado
          processedUuids.add(uuid)

          // Coleta informações do colaborador
          const collaborator = await getDocument('colaboradores', uuid)
          const newFavorite = {
            dt_created: DateTimeNow(),
            search: CreateSearchField(collaborator),
          }

          console.log(newFavorite)

          // Adiciona colaborador aos favoritos do cliente
          await setSubDocument('clientes', user.client.id, 'favorites', uuid, newFavorite)
          await setSubDocument('colaboradores', uuid, 'favorites', user.client.id, {
            idClient: user.client.id,
            dt_created: DateTimeNow(),
            permission: false,
          })

          // Coleta informações do job para compor o novo tim
          const collaboratorInfo = {
            cpf: collaborator.cpf,
            name: collaborator.name,
            search: CreateSearchField(collaborator),
            photoUrl: collaborator.photoUrl,
            uuid,
          }

          console.log(collaboratorInfo)

          // Adiciona colaborador ao novo time
          newTeam.push(collaboratorInfo)
        })

        await Promise.all(promiseFavoriteAll)
        console.log([...processedUuids]) // Exibe todos os UUIDs únicos processados
        setAlerts((prev) => [
          ...prev,
          {
            type: 'success',
            title: 'Staff Favoritada',
            msg: 'staff favoritada e nova equipe criada com sucesso...',
          },
        ])
      } else {
        const listFavorite = newFinallyInfo?.list_favorite || []

        promisseFavoriteAll = listFavorite.map(async (candidacy) => {
          const uuid = candidacy.uuid
          // coleta as infos do colaborador
          const colaborator = await getDocument('colaboradores', uuid)

          // adiciona aos favoritos da empresa
          await setSubDocument('clientes', user.client.id, 'favorite', uuid, {
            uuid,
            dt_created: DateTimeNow(),
            search: CreateSearchField(colaborator),
            status: false,
          })
          await setSubDocument('colaboradores', uuid, 'favorites', user.client.id, {
            idClient: user.client.id,
            dt_created: DateTimeNow(),
            status: false,
          })

          // Coleta informações do job para compor o novo tim
          const collaboratorInfo = {
            cpf: colaborator.cpf,
            name: colaborator.name,
            search: CreateSearchField(colaborator),
            photoUrl: colaborator.photoUrl,
            uuid,
          }
          newTeam.push(collaboratorInfo)
        })
        console.log(listFavorite)
        await Promise.all(promisseFavoriteAll)
        setAlerts((prev) => [
          ...prev,
          {
            type: 'success',
            title: 'Lista de colaboradores Favoritada',
            msg: 'Lsta de favoritos salva com sucesso...',
          },
        ])
      }

      if (createNewTeams) {
        // Cria o novo time e adiciona aos subdocumentos do cliente
        const newTeamDocument = {
          teams: newTeam,
          createdBy: user.user.nome,
          description: `Criado na finalização do evento: ${eventInfos.name} - ${eventInfos.round} ás ${DateTimeNow()} pelo colaborador ${user.user.nome}`,
          dt_created: DateTimeNow(),
          name: nameTeams !== '' ? nameTeams : `Staff - ${eventInfos.name} - ${eventInfos.round} - ${DateNow()}`,
        }
        await addSubDocument('clientes', user.client.id, 'teams', newTeamDocument)
        setAlerts((prev) => [
          ...prev,
          {
            type: 'success',
            title: 'Nova Equipe Criada',
            msg: 'Criamos uma nova equipe com a staff desse evento',
          },
        ])
      }
      return true
    } catch (error) {
      return true
    }
  }

  const blockedStaff = async (hasBlocked, eventInfos, newFinallyInfo) => {
    try {
      if (!hasBlocked) return true

      const listBlockeds = newFinallyInfo?.list_blocked || []
      const reasonDefault = newFinallyInfo?.motivoDefaultBlocked || ' '
      const uuidprocesseds = new Set()
      console.log(newFinallyInfo)
      const promisseBlockedColaborator = listBlockeds.map(async (candidacy) => {
        const uuid = candidacy.uuid
        console.log(uuid)

        if (uuidprocesseds.has(uuid)) return

        // Marca o UUID como processado
        uuidprocesseds.add(uuid)

        const newBlockedForClient = {
          dt_created: DateTimeNow(),
          createdBy: candidacy.createdBy || user.user.nome,
          reason: candidacy?.motivoBlocked || reasonDefault,
          uuid,
          search: CreateSearchField(candidacy),
          infoExtra: `Bloqueado pela finalização do evento ${eventInfos.name} - ${eventInfos.round} - ${eventInfos.id}`,
        }

        const newBlockedForColaborator = {
          dt_created: DateTimeNow(),
          createdBy: candidacy.createdBy || user.user.nome,
          reason: candidacy?.motivoBlocked || reasonDefault,
          clientId: user.client.id,
        }
        console.log(newBlockedForColaborator)
        console.log(newBlockedForClient)

        await setSubDocument('colaboradores', uuid, 'blockedIn', user.client.id, newBlockedForColaborator)
        await setSubDocument('clientes', user.client.id, 'blockeds', uuid, newBlockedForClient)
      })

      await Promise.all(promisseBlockedColaborator).finally(console.log(uuidprocesseds))
      console.log(uuidprocesseds)

      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Colaboradores Bloqueados',
          msg: 'Lista de colaboradores foi bloqueada com sucesso...',
        },
      ])

      return true
    } catch (e) {
      return false
    }
  }

  const finallyEvent = async () => {
    if (!user.user.permissions.finallyEvent_submit) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }

    const newFinallyInfo = await schemafinallyEvent(formContext)
    const hasDebts = newFinallyInfo.hasDebts
    const hasFavorites = newFinallyInfo.hasFavorites
    const hasBlocked = newFinallyInfo.hasBlocked
    setStatusLoading('Salvando Informações...')
    await saveFinnalyInfoEvent()
    // finalizar candidaturas

    // regras dos status
    const getStaff = await getSubDocuments('events', id, 'staff')
    const eventInfos = await getDocument('events', id)

    let passed
    setStatusLoading('Finalizando Staff...')
    passed = await finnalyStaff(hasDebts, getStaff, newFinallyInfo)
    if (!passed) return
    // favoritar candidaturas
    setStatusLoading('Favoritando Colaboradores...')
    passed = await favoriteStaff(hasFavorites, getStaff, eventInfos, newFinallyInfo)
    if (!passed) return
    // bloquear candidaturas
    setStatusLoading('Bloqueando Colaboradores...')
    passed = await blockedStaff(hasBlocked, eventInfos, newFinallyInfo)
    if (!passed) return

    setStatusLoading('Exportando Planilha de pagamento...')
    const newFinally = { dtFinally: DateTimeNow(), finallyBy: user.user.nome }
    console.log(newFinally)
    await updateDocument('events', id, { status: 'closed', ...newFinally })
    await setFormContext((prev) => ({ ...prev, statusEvent: 'closed', ...newFinally }))
    // deixa de um jeito que sempre recrie a finalziação, e sobrescreva a outra para não gerar duplicatas
    // criar planilha de pagamento com histórico completo de entradas e saida

    await ExportAllStaffPayment(id, setAlerts)

    setStatusLoading('')
    await SetHistoryEvent(id, user.user.nome, `Evento Finalizado`)
  }

  const schemaCandidacy = (candidacy) => {
    console.log(candidacy)
    return {
      id: candidacy.id,
      uuid: candidacy.uuid,
      name: candidacy.name,
      cpf: candidacy.cpf,
      positionName: candidacy.positionName,
      date: candidacy.date,
      idJobOffers: candidacy.idJobOffers,
      createdBy: user.user.nome,
    }
  }

  const SelectCandidacy = async (candidacy) => {
    const newCandidacy = schemaCandidacy(candidacy)
    const type = formContext?.typeSelectCandidacy
    if (type !== '' && type !== undefined) {
      const field = `list_${type}`
      console.log(type)
      console.log(candidacy.status < 7 || candidacy.status > 10)
      if (type === 'debts' && (candidacy.status < 7 || candidacy.status > 10)) {
        return setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Seleção bloqueada',
            msg: 'Não é possível selecionar ajustes/descontos para candidaturas que ainda não completaram o processo de check-out.',
          },
        ])
      }
      if (type === 'favorite' && candidacy.status < 7 && candidacy.status > 10) {
        return setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Seleção bloqueada',
            msg: 'Não é possível favoritar candidaturas que ainda não completaram o processo de check-out.',
          },
        ])
      }

      return setFormContext((prev) => {
        const currentList = prev?.[field] || []
        const index = currentList.length

        return {
          ...prev,
          [field]: [...currentList, { ...newCandidacy, index, ...(field === 'list_debts' && { typeDebt: 'D' }) }],
        }
      })
    }
  }

  const DeleteCandidacy = async (index, field) => {
    console.log(index, field)
    setFormContext((prev) => {
      const newlist = DeleteValueInArrayOfObject(prev?.[field], 'index', index) // Clone do objeto para evitar mutação direta
      console.log(newlist)
      return { ...prev, [field]: [...newlist] } // Retorno do novo estado com a lista atualizada
    })
  }

  const DuplicateCandidacy = async (candidacy, field) => {
    setFormContext((prev) => {
      const currentList = prev?.[field] || []
      const index = currentList.length

      return {
        ...prev,
        [field]: [...currentList, { ...candidacy, index }],
      }
    })
  }

  const FetchGetDatas = async (page) => {
    await TryCatch(
      () => {
        getDatas({ query: filter, pageQuery: page })
      },
      setLoading,
      setAlerts,
    )
  }

  const FetchGetForm = async (page) => {
    await TryCatch(getForm, setLoading, setAlerts)
  }

  const FetchGetDatasQuery = async () => {
    await TryCatch(queryData, setLoading, setAlerts)
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const HandleFinallyEvents = async () => {
    await TryCatch(finallyEvent, setLoading, setAlerts)
  }

  const HandleSaveFinallyInfoEvent = async () => {
    await TryCatch(saveFinnalyInfoEvent, setLoading, setAlerts)
  }

  const HandleExportExcelPayment = async () => {
    if (!user.user.permissions.staffEvent_exportPayment) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }
    await TryCatch(
      async () => {
        await ExportAllStaffPayment(id, setAlerts)
      },
      setLoading,
      setAlerts,
    )
    await SetHistoryEvent(id, user.user.nome, `Planilha de Pagamentos extraida`)
  }

  useEffect(() => {
    const updateContexts = async () => {
      await setDataContext({})
      await setInfosContext({})
      await FetchGetInfos()
      await FetchGetForm()
    }
    updateContexts()
  }, [])

  useEffect(() => {
    console.log(page)
    if (page > 0) {
      FetchGetDatas(page)
    }
  }, [page])

  useEffect(() => {
    console.log(formContext.list_debts)
  }, [formContext])

  useEffect(() => {
    setDataContext([])
    setFilter((prev) => ({ ...prev, date: 'todos', idPosition: 'todos', status: 'todos' }))
  }, [modalSelectCandidacy])

  return {
    clientId,
    navigate,
    loading,
    filter,
    setFilter,
    setFormContext,
    modalSelectCandidacy,
    setModalSelectCandidacy,
    dataContext,
    formContext,
    infosContext,
    FetchGetDatas,
    FetchGetInfos,
    FetchGetDatasQuery,
    page,
    setPage,
    SelectCandidacy,
    DuplicateCandidacy,
    DeleteCandidacy,
    HandleFinallyEvents,
    HandleSaveFinallyInfoEvent,
    statusLoading,
    HandleExportExcelPayment,
  }
}
