import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { useGlobalContext } from '../../../../context/ContextGlobal'
import { Page } from '../../../../components/layouts/page'
import { TransformationforMask } from '../../../../utils/masks'
import { GetStatusClient } from '../../../administration/clients/_utils'

export const ClientInfoEnterprise = () => {
  const { user } = useGlobalContext()
  const cliente = user?.client || ''

  return (
    <>
      {/* <strong>{TransformationforMask(cliente?.id, 'title')}</strong> */}

      <Page.Section className="is-fullheight-with-navbar">
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-5">
              <strong>{TransformationforMask(cliente?.razaoSocial, 'title')}</strong>
            </p>
          </Column.Content>
        </Column.Root>

        <Column.Root>
          <Column.Content size="is-10">
            <Input.Root>
              <Input.Label className="has-text-grey-light">CPF / CNPJ</Input.Label>
              <Input.Contents>
                <Input.ReadOnly className="is-rounded">{cliente?.cpfCnpj}</Input.ReadOnly>
              </Input.Contents>
            </Input.Root>

            <Input.Root>
              <Input.Label className="has-text-grey-light">Situação</Input.Label>
              <Input.Contents>
                <Input.ReadOnly className="is-rounded">{GetStatusClient(cliente?.status).value}</Input.ReadOnly>
              </Input.Contents>
            </Input.Root>

            <Column.Root className="mt-2">
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Inscrição Estadual</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.isncEstadual}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">CNAE</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.cnae}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content></Column.Content>
            </Column.Root>

            <div className="my-3">
              <Column.Root>
                <Column.Content>
                  <Input.Root>
                    <Input.Label className="has-text-grey-light">Logradouro</Input.Label>
                    <Input.Contents>
                      <Input.ReadOnly className="is-rounded">{cliente?.endereco}</Input.ReadOnly>
                    </Input.Contents>
                  </Input.Root>
                </Column.Content>
                <Column.Content>
                  <Input.Root>
                    <Input.Label className="has-text-grey-light">Número</Input.Label>
                    <Input.Contents>
                      <Input.ReadOnly className="is-rounded">{cliente?.endereco_numero}</Input.ReadOnly>
                    </Input.Contents>
                  </Input.Root>
                </Column.Content>
              </Column.Root>

              <Column.Root>
                <Column.Content>
                  <Input.Root>
                    <Input.Label className="has-text-grey-light">Complemento</Input.Label>
                    <Input.Contents>
                      <Input.ReadOnly className="is-rounded">{cliente?.complemento}</Input.ReadOnly>
                    </Input.Contents>
                  </Input.Root>
                </Column.Content>
                <Column.Content>
                  <Input.Root>
                    <Input.Label className="has-text-grey-light">Bairro</Input.Label>
                    <Input.Contents>
                      <Input.ReadOnly className="is-rounded">{cliente?.bairro}</Input.ReadOnly>
                    </Input.Contents>
                  </Input.Root>
                </Column.Content>
              </Column.Root>

              <Column.Root className="my-3">
                <Column.Content>
                  <Input.Root>
                    <Input.Label className="has-text-grey-light">Municipio</Input.Label>
                    <Input.Contents>
                      <Input.ReadOnly className="is-rounded">{cliente?.endereco}</Input.ReadOnly>
                    </Input.Contents>
                  </Input.Root>
                </Column.Content>
                <Column.Content>
                  <Input.Root>
                    <Input.Label className="has-text-grey-light">Cep</Input.Label>
                    <Input.Contents>
                      <Input.ReadOnly className="is-rounded">{cliente?.cep}</Input.ReadOnly>
                    </Input.Contents>
                  </Input.Root>
                </Column.Content>
              </Column.Root>
            </div>

            <Column.Root className="my-3">
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Responsavel</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.reponsavel_name}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>

              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Telefone</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.responsavel_telefone}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">E-mail Principal</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.responsavel_email}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
            </Column.Root>

            <Column.Root className="my-3">
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Banco</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.reponsavel_name}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>

              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Conta</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.responsavel_telefone}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Agência</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.responsavel_email}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>

              <Column.Content>
                <Input.Root>
                  <Input.Label className="has-text-grey-light">Pix</Input.Label>
                  <Input.Contents>
                    <Input.ReadOnly className="is-rounded">{cliente?.responsavel_email}</Input.ReadOnly>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
            </Column.Root>
          </Column.Content>
        </Column.Root>
      </Page.Section>
    </>
  )
}
