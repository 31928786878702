import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { getDocumentsWithQuerys, getSubDocuments } from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'
import { ConvertToTimestamp } from '../../../../utils/dates'

export const usePaymentsClients = () => {
  const { id } = useParams()
  const { user, loading, setLoading, setAlerts, infosContext, setDataContext, dataContext, formContext } =
    useGlobalContext()

  const userName = user.user.nome

  const [search, setSearch] = useState('')
  const [modalHistory, setModalHistory] = useState(false)
  // const { FetchClientInfos } = useAdminClients()

  const navigate = useNavigate()

  const getData = async () => {
    const idclientOmie = formContext?.idClientOmie || ''
    console.log(idclientOmie)
    const query = [{ field: 'codigo_cliente_fornecedor', operator: '==', value: Number(idclientOmie) }]
    const getPaymentsProssesing = await getDocumentsWithQuerys('_payments', query)
    const getPaymentsClient = await getSubDocuments('clientes', id, 'payments')

    const payments = [...getPaymentsProssesing, ...getPaymentsClient].sort(
      (a, b) =>
        ConvertToTimestamp((b?.dt_created || b.data_cred).replace('T', ' ')) -
        ConvertToTimestamp((a?.dt_created || a.data_cred).replace('T', ' ')),
    )
    setDataContext({ payments })
  }

  const FetchGetData = async () => {
    await TryCatch(getData, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetData()
  }, [])

  const payments = (dataContext?.payments || []).sort((a, b) => {
    return Number(b.numero_pedido) - Number(a.numero_pedido)
  })

  const dataFiltered =
    search !== ''
      ? payments.filter((payments) => {
          const searchString = `${payments.numero_pedido}||${payments.value}||${payments.status}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : payments

  return {
    id,
    search,
    setSearch,
    navigate,
    infosContext,
    loading,
    setLoading,
    dataFiltered,
    FetchGetData,
    modalHistory,
    setModalHistory,
    dataContext,
    userName,
  }
}
