import React from 'react'
import { Outlet } from 'react-router-dom'

import { Page } from '../../../../components/layouts/page'
import { SideBar } from '../../../../components/layouts/sidebar'
import { Icon } from '../../../../components/icons'
import { Path } from '../../../../router/paths'
import { useCollaboratorEditSidebar } from '../_hooks/useCollaboratorEditSidebar'

export const CollaboratorEditSidebar = () => {
  const { loading, id, location } = useCollaboratorEditSidebar()

  return (
    <>
      <Page.Content
        className=""
        style={{
          marginTop: 80,
          marginLeft: 80,
          marginRight: 10,
          marginBottom: 500,
        }}
      >
        <Outlet />
      </Page.Content>

      <Page.SideBar>
        <SideBar.Root>
          <SideBar.List>
            {!loading ? (
              <SideBar.Item title={'Voltar'} link={Path.AdminCollaborators} loading={loading}>
                <Icon size={30}>arrowLeft</Icon>
              </SideBar.Item>
            ) : (
              <SideBar.Item title={'Aguarde Conclusão do carregamento...'}>
                <Icon size={30} className="has-text-grey-lighter">
                  arrowLeft
                </Icon>
              </SideBar.Item>
            )}

            <SideBar.Item
              title={'Informações do Colaborador'}
              link={Path.EditAdminCollaborator(id)}
              activate={location.pathname === Path.EditAdminCollaborator(id)}
              loading={loading}
            >
              <Icon size={30}>user</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Cargos'}
              link={Path.EditAdminCollaboratorPositions(id)}
              activate={location.pathname === Path.EditAdminCollaboratorPositions(id)}
              loading={loading}
            >
              <Icon size={30}>job</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Financeiro/Planos'}
              link={Path.EditAdminCollaboratorFinancyOrPlans(id)}
              activate={location.pathname === Path.EditAdminCollaboratorFinancyOrPlans(id)}
              loading={loading}
            >
              <Icon size={30}>bill</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Eventos Candidatados'}
              link={Path.EditAdminCollaboratorCandidateEvents(id)}
              activate={location.pathname === Path.EditAdminCollaboratorCandidateEvents(id)}
              loading={loading}
            >
              <Icon size={30}>calendar</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Empresas Bloqueadas'}
              link={Path.EditAdminCollaboratorBlockedCompanies(id)}
              activate={location.pathname === Path.EditAdminCollaboratorBlockedCompanies(id)}
              loading={loading}
            >
              <Icon size={30}>block</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Empresas Favoritadas'}
              link={Path.EditAdminCollaboratorFavoriteCompanies(id)}
              activate={location.pathname === Path.EditAdminCollaboratorFavoriteCompanies(id)}
              loading={loading}
            >
              <Icon size={30}>favorite</Icon>
            </SideBar.Item>

            <SideBar.Space size={100}></SideBar.Space>

            {/* <SideBar.Item
              title={formContext?.statusEvent === 'open' ? 'Finalizar Evento' : 'Finalização do evento'}
              link={Path.ClientStaffFinally(id)}
              activate={location.pathname === Path.ClientStaffFinally(id)}
              loading={loading}
            >
              <Icon size={30} className={formContext?.statusEvent === 'open' ? 'has-text-danger' : 'has-text-dark'}>
                calendar-close
              </Icon>
            </SideBar.Item> */}
          </SideBar.List>
        </SideBar.Root>
      </Page.SideBar>

      {/* Componente do REACT DOM que move a pagina para cima ao trocar de paginas, só funciona para as paginas que estão em children  */}
    </>
  )
}
