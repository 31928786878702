import React, { createContext, useState, useContext, useEffect } from 'react'

import { GetItemSession, SetItemSession } from '../utils/sessionStorage'

// Crie o contexto
const GlobalContext = createContext()

// Componente Provider
export const ContextGlobal = ({ children }) => {
  // adicionar aqui o states para ser usados nas telas seguintes

  // Alguns contextos sempre será salvo na memória da sessão do navegador
  // isso evita erros na hora da atualização da pagina
  const saveSessionData = (campo, context) => {
    SetItemSession({ key: campo, value: context })
  }

  const getSessionData = (campo, returnDefault = '') => {
    const getData = GetItemSession(campo, returnDefault)
    return getData
  }

  const [errors, setErrors] = useState({})
  const [userContext, setUserContext] = useState({}) // state usado para tela de usuários
  const [setoresContext, setSetoresContext] = useState({}) // state usado para tela de setores
  const [plansContext, setPlansContext] = useState({}) // state usado para tela de Planos
  const [documentsContext, setDocumentsContext] = useState({}) // state usado para tela de Documentos
  const [consentimentosContext, setConsentimentosContext] = useState({}) // state usado para tela de Consentimentos
  const [cargosContext, setCargosConstext] = useState({}) // state usado para tela de Cargos
  const [clientesContext, setClientesContext] = useState({}) // state usado para tela de Clientes
  const [collaboratorsContext, setCollaboratorsContext] = useState({}) // state usado para tela de Colaboradores

  const [user, setUser] = useState(getSessionData('user', []))
  const [alerts, setAlerts] = useState(getSessionData('alerts', []))
  const [loading, setLoading] = useState(getSessionData('loading', false))
  const [statusLoading, setStatusLoading] = useState(getSessionData('statusLoading', ''))
  const [navBar, setNavBar] = useState(false)
  const [infosContext, setInfosContext] = useState(getSessionData('infos', []))
  const [dataContext, setDataContext] = useState({})
  const [formContext, setFormContext] = useState(getSessionData('form', {}))

  useEffect(() => {
    saveSessionData('form', formContext)
  }, [formContext])

  useEffect(() => {
    saveSessionData('infos', infosContext)
  }, [infosContext])

  useEffect(() => {
    saveSessionData('loading', loading)
  }, [loading])

  useEffect(() => {
    saveSessionData('statusLoading', statusLoading)
  }, [statusLoading])

  useEffect(() => {
    saveSessionData('alerts', alerts)
  }, [alerts])

  useEffect(() => {
    saveSessionData('user', user)
  }, [user])

  const contexts = {
    user,
    setUser,
    alerts,
    setAlerts,
    navBar,
    setNavBar,
    errors,
    setErrors,
    userContext,
    setUserContext,
    setoresContext,
    setSetoresContext,
    plansContext,
    setPlansContext,
    documentsContext,
    setDocumentsContext,
    consentimentosContext,
    setConsentimentosContext,
    cargosContext,
    setCargosConstext,
    clientesContext,
    setClientesContext,
    collaboratorsContext,
    setCollaboratorsContext,

    dataContext,
    setDataContext,
    infosContext,
    setInfosContext,
    formContext,
    setFormContext,
    loading,
    setLoading,
    statusLoading,
    setStatusLoading,
  }

  return <GlobalContext.Provider value={contexts}>{children}</GlobalContext.Provider>
}

// Hook para consumir o contexto
export const useGlobalContext = () => {
  return useContext(GlobalContext)
}
