import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import { deleteSubDocument, getSubDocument, getSubDocumentsWithQuerys } from '../../../../firebase/firestore'
import { GetPaginationBlockedsCollaborators } from '../../../../firebase/functions'

export const useClientBlockeds = () => {
  const {
    user,
    setAlerts,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    setLoading,
    loading,
  } = useGlobalContext()

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const clientId = user.client.id

  const getDatas = async () => {
    const query = { search: search.length > 0 ? search : null }
    const getFavotires = await GetPaginationBlockedsCollaborators(page, 100, clientId, query)
    console.log(getFavotires)
    setDataContext({ blockeds: getFavotires })
  }

  const deleteFavorite = async (uuid) => {
    const querys = [{ field: 'idClient', operator: '==', value: clientId }]
    const ExistInColab = await getSubDocumentsWithQuerys('colaboradores', uuid, 'blockedIn', querys)
    console.log(ExistInColab)
    if (ExistInColab.length > 0) {
      const deleteInColabs = ExistInColab.map(async (register) => {
        await deleteSubDocument('colaboradores', uuid, 'blockedIn', register.id)
      })

      await Promise.all(deleteInColabs).then((response) => {
        setAlerts((prev) => [
          ...prev,
          {
            type: 'success',
            title: `Bloqueio Removido`,
            msg: `Registro de Bloqueio removido do cadastro do colaborador.`,
          },
        ])
      })
    }

    const ExistInClient = await getSubDocument('clientes', clientId, 'blockeds', uuid)
    console.log(ExistInClient)
    if (Object.keys(ExistInClient).length > 0) {
      await deleteSubDocument('clientes', clientId, 'blockeds', uuid)
      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: `Bloqueio Removido`,
          msg: `Registro de Bloqueio removido da sua base de dados.`,
        },
      ])
    }

    await FetchGetDatas()
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const HandleDeleteFavorite = async (uuid) => {
    await TryCatch(
      async () => {
        await deleteFavorite(uuid)
      },
      setLoading,
      setAlerts,
    )
  }

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    FetchGetDatas()
  }, [])

  return {
    page,
    setPage,
    loading,
    search,
    setSearch,
    dataContext,
    FetchGetDatas,
    HandleDeleteFavorite,
  }
}
