import React from 'react'

import { Column } from '../../../../../components/layouts/columns'
import { Input } from '../../../../../components/ui/input'
import { Select } from '../../../../../components/ui/select'
import { Checkbox } from '../../../../../components/ui/checkbox'

export const FormPlansLayout = ({ values, setValues, disabledList }) => {
  return (
    <>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Nome
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'name'}
                required={true}
                disabled={disabledList?.nome}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Input.Root className="mb-5">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Descricao
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={values}
                setValue={setValues}
                field={'desc'}
                required={true}
                disabled={disabledList?.descricao}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Column.Root>
            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                  Valor
                </Input.Label>
                <Input.Contents alignIcon={'left'}>
                  <Input.Icon align="is-left">R$</Input.Icon>
                  <Input.Prompt
                    className="is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'value'}
                    required={true}
                    type="number"
                    disabled={disabledList?.value}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>

            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                  Faturamento a cada
                </Input.Label>
                <Select.Root>
                  <Select.Content
                    color="is-grey"
                    className="is-fullwidth is-rounded"
                    value={values}
                    setValue={setValues}
                    field={'typeExpired'}
                    disabled={disabledList?.type_expired}
                  >
                    <Select.Option value={'0'} title={'Não Fatura'} />
                    <Select.Option value={'15'} title={'15 Dias'} />
                    <Select.Option value={'30'} title={'30 Dias (1 Mês)'} />
                    <Select.Option value={'60'} title={'60 Dias (2 Meses)'} />
                    <Select.Option value={'90'} title={'90 Dias (3 Meses)'} />
                    <Select.Option value={'180'} title={'180 Dias (6 Meses)'} />
                    <Select.Option value={'365'} title={'365 Dias'} />
                  </Select.Content>
                </Select.Root>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <Column.Root>
            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                  Código de Cupom
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={values.codCupom}
                    setValue={(value) => {
                      setValues((prev) => ({
                        ...prev,
                        codCupom: value.startsWith('#')
                          ? String(value).toUpperCase().trim()
                          : `#${String(value).toUpperCase().trim()}`,
                      }))
                    }}
                    disabled={disabledList?.codCupom}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>

            <Column.Content></Column.Content>
          </Column.Root>

          <Column.Root>
            <Column.Content>
              <Column.Root>
                <Column.Content>
                  <Checkbox.Root text="ISS Retido?">
                    <Checkbox.Content value={values} setValue={setValues} field={'cRetISS'}></Checkbox.Content>
                  </Checkbox.Root>

                  {values?.cRetISS && (
                    <Input.Root className="mb-5">
                      <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                        Alíquota de ISS
                      </Input.Label>
                      <Input.Contents>
                        <Input.Prompt
                          type="number"
                          className="is-rounded"
                          value={values}
                          setValue={setValues}
                          field={'nAliqISS'}
                          disabled={disabledList?.nAliqISS}
                          required={true}
                        ></Input.Prompt>
                      </Input.Contents>
                    </Input.Root>
                  )}
                </Column.Content>
              </Column.Root>
            </Column.Content>

            <Column.Content>
              <Column.Root>
                <Column.Content>
                  <Checkbox.Root text="PIS Retido?">
                    <Checkbox.Content value={values} setValue={setValues} field={'cRetPIS'}></Checkbox.Content>
                  </Checkbox.Root>

                  {values?.cRetPIS && (
                    <Input.Root className="mb-5">
                      <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                        Alíquota do PIS
                      </Input.Label>
                      <Input.Contents>
                        <Input.Prompt
                          className="is-rounded"
                          value={values}
                          setValue={setValues}
                          field={'nAliqPIS'}
                          disabled={disabledList?.codCupom}
                          required={true}
                          type="number"
                        ></Input.Prompt>
                      </Input.Contents>
                    </Input.Root>
                  )}
                </Column.Content>
              </Column.Root>
            </Column.Content>

            <Column.Content>
              <Column.Root>
                <Column.Content>
                  <Checkbox.Root text="COFINS Retido?">
                    <Checkbox.Content value={values} setValue={setValues} field={'cRetCOFINS'}></Checkbox.Content>
                  </Checkbox.Root>
                  {values?.cRetCOFINS && (
                    <Input.Root className="mb-5">
                      <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                        Alíquota de COFINS
                      </Input.Label>
                      <Input.Contents>
                        <Input.Prompt
                          className="is-rounded"
                          value={values}
                          setValue={setValues}
                          field={'nAliqCOFINS'}
                          disabled={disabledList?.codCupom}
                          required={true}
                          type="number"
                        ></Input.Prompt>
                      </Input.Contents>
                    </Input.Root>
                  )}
                </Column.Content>
              </Column.Root>
            </Column.Content>

            <Column.Content>
              <Column.Root>
                <Column.Content>
                  <Checkbox.Root text="CSLL Retido?">
                    <Checkbox.Content value={values} setValue={setValues} field={'cRetCSLL'}></Checkbox.Content>
                  </Checkbox.Root>
                  {values?.cRetCSLL && (
                    <Input.Root className="mb-5">
                      <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                        Alíquota de CSLL
                      </Input.Label>
                      <Input.Contents>
                        <Input.Prompt
                          className="is-rounded"
                          value={values}
                          setValue={setValues}
                          field={'nAliqCSLL'}
                          disabled={disabledList?.codCupom}
                          required={true}
                          type="number"
                        ></Input.Prompt>
                      </Input.Contents>
                    </Input.Root>
                  )}
                </Column.Content>
              </Column.Root>
            </Column.Content>

            <Column.Content>
              <Column.Root>
                <Column.Content>
                  <Checkbox.Root text="IR Retido ?">
                    <Checkbox.Content value={values} setValue={setValues} field={'cRetIR'}></Checkbox.Content>
                  </Checkbox.Root>
                  {values?.cRetIR && (
                    <Input.Root className="mb-5">
                      <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                        Alíquota do IR
                      </Input.Label>
                      <Input.Contents>
                        <Input.Prompt
                          className="is-rounded"
                          value={values}
                          setValue={setValues}
                          field={'nAliqIR'}
                          disabled={disabledList?.codCupom}
                          required={true}
                          type="number"
                        ></Input.Prompt>
                      </Input.Contents>
                    </Input.Root>
                  )}
                </Column.Content>
              </Column.Root>
            </Column.Content>

            <Column.Content>
              <Column.Root>
                <Column.Content>
                  <Checkbox.Root text="INSS Retido ?">
                    <Checkbox.Content value={values} setValue={setValues} field={'cRetINSS'}></Checkbox.Content>
                  </Checkbox.Root>
                  {values?.cRetINSS && (
                    <Input.Root className="mb-5">
                      <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                        Alíquota de INSS
                      </Input.Label>
                      <Input.Contents>
                        <Input.Prompt
                          className="is-rounded"
                          value={values}
                          setValue={setValues}
                          field={'nAliqINSS'}
                          disabled={disabledList?.codCupom}
                          required={true}
                          type="number"
                        ></Input.Prompt>
                      </Input.Contents>
                    </Input.Root>
                  )}
                </Column.Content>
              </Column.Root>
            </Column.Content>
          </Column.Root>
        </Column.Content>
      </Column.Root>
    </>
  )
}
