import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { ClientsFormEventsInfosLayout } from './_form/clientsFormEventsInfosLayout'
import { useClientEditEvents } from './_hooks/useClientEditEvents'
import { Button } from '../../../components/ui/button'
import { Path } from '../../../router/paths'
import { Icon } from '../../../components/icons'

export const ClientEditEvents = () => {
  const { id, loading, formContext, setFormContext, HandleEditEvents } = useClientEditEvents()

  return (
    <Page.Section className="is-fullheight-with-navbar mt-6 mx-3">
      <Form.Root
        action={() => {
          HandleEditEvents(id)
        }}
      >
        <Column.Root>
          <Column.Content className="my-5">
            <p className="subtitle is-size-4">
              <Icon size={30} className={'mr-3'}>
                info
              </Icon>
              Informações do Evento
            </p>
          </Column.Content>
        </Column.Root>
        <ClientsFormEventsInfosLayout
          loading={loading}
          formContext={formContext}
          setFormContext={setFormContext}
          options={{}}
          disabledList={{}}
        />
        <Column.Root className="mt-5 mb-6">
          <Column.Content>
            <Button.Root align="right">
              <Button.Content>
                <Button.A color="is-light" link={Path.ClientEvents} loading={loading}>
                  Cancelar
                </Button.A>
              </Button.Content>
              <Button.Content>
                <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                  Salvar
                </Button.Bt>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
      </Form.Root>
    </Page.Section>
  )
}
