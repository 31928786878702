import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TransformationforMask } from '../../../../utils/masks'
import { useValidateCollaboratorModal } from './useValidateCollaboratorModal'
import { getAddressByCep } from '../../../../utils/consultingCEP'
import { isValidCPF } from '../../../../utils/validateCPF'

export const useCollaboratorsForm = () => {
  const { loading, setAlerts, formContext, setFormContext } = useGlobalContext()

  const { openModal, handleOpenValidateModal } = useValidateCollaboratorModal()

  const emergencyContactsGroup = formContext?.emergencyContacts ?? [{ name: '', kinship: '', phone: '' }]
  const emergencyContactsGroupCount = emergencyContactsGroup.length

  const submitFormDisabled = formContext?.estadoCivil && formContext?.tipoDocumento && formContext?.uf

  const handleCPFField = (cpf) => {
    const formattedCPF = TransformationforMask(cpf, 'cpf')

    setFormContext((prev) => {
      return {
        ...prev,
        cpf: formattedCPF,
      }
    })

    if (formattedCPF.length === 14 && !isValidCPF(formattedCPF)) {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'CPF Inválido',
          msg: `O CPF digitado não existe!`,
          type: 'error',
        },
      ])
    }
  }

  const handleZipcodeField = async (zipcode) => {
    const formattedZipcode = TransformationforMask(zipcode, 'cep')

    setFormContext((prev) => {
      return {
        ...prev,
        cep: formattedZipcode,
      }
    })

    if (formattedZipcode.length === 9) {
      const zipcodeQueryResult = await getAddressByCep(formattedZipcode.substr(0, 9))

      if (zipcodeQueryResult.error) {
        return setAlerts((prev) => [
          ...prev,
          {
            title: 'CEP Inválido',
            msg: zipcodeQueryResult.error,
            type: 'error',
          },
        ])
      }

      setFormContext((prev) => {
        return {
          ...prev,
          logradouro: zipcodeQueryResult.logradouro,
          bairro: zipcodeQueryResult.bairro,
          cidade: zipcodeQueryResult.localidade,
          uf: zipcodeQueryResult.uf,
        }
      })
    }
  }

  const handleEmergencyFieldsChange = (index, event) => {
    const { name, value } = event.target
    const newEmergencyContactsGroup = [...emergencyContactsGroup]

    newEmergencyContactsGroup[index][name] = TransformationforMask(value)

    setFormContext((prevState) => {
      return {
        ...prevState,
        emergencyContacts: newEmergencyContactsGroup,
      }
    })
  }

  // funções para os campos para lidar com os campos de emergência
  const handleAddEmergencyContact = () => {
    setFormContext((prevState) => {
      return {
        ...prevState,
        emergencyContacts: [...prevState.emergencyContacts, { name: '', kinship: '', phone: '' }],
      }
    })
  }

  const handleDeleteEmergencyContact = (index) => {
    if (emergencyContactsGroupCount > 1) {
      const updatedContacts = emergencyContactsGroup.filter((_, i) => i !== index)

      setFormContext((prevState) => {
        return {
          ...prevState,
          emergencyContacts: updatedContacts,
        }
      })
    }
  }

  const blockUnblockCollaborator = () => {
    return setFormContext((prevState) => {
      const currentCollaboratorStatus = prevState.status
      const newCollaboratorStatus = currentCollaboratorStatus === 9 ? 1 : 9 // Bloqueado para verificado e vice-versa
      return {
        ...prevState,
        status: newCollaboratorStatus,
      }
    })
  }

  const handleValidateCollaboratorCheckbox = (field, fieldDescription, fieldValue, index = null) => {
    setFormContext((prevState) => {
      const inEdition = {
        ...prevState.inEdition,
        emergencyContacts: [...(prevState.inEdition?.emergencyContacts || [])],
      }

      if (index !== null) {
        // Garante que o array tem espaço para o índice especificado
        if (!inEdition.emergencyContacts[index]) {
          inEdition.emergencyContacts[index] = {}
        }

        const contact = inEdition.emergencyContacts[index]

        if (field in contact) {
          // Remove o campo se ele já existir
          const updatedContact = { ...contact }
          delete updatedContact[field]

          const updatedContacts =
            Object.keys(updatedContact).length > 0
              ? inEdition.emergencyContacts.map((item, idx) => (idx === index ? updatedContact : item))
              : inEdition.emergencyContacts.filter((_, idx) => idx !== index)

          return {
            ...prevState,
            inEdition: { ...inEdition, emergencyContacts: updatedContacts },
          }
        } else {
          // Adiciona o campo caso não exista
          const updatedContact = {
            ...contact,
            [field]: { title: fieldDescription, fieldValue, reason: '' },
          }

          const updatedContacts = inEdition.emergencyContacts?.length
            ? inEdition.emergencyContacts.map((item, idx) => (idx === index ? updatedContact : item))
            : [updatedContact] // Se estiver vazio, inicializa com o novo contato

          return {
            ...prevState,
            inEdition: { ...inEdition, emergencyContacts: updatedContacts },
          }
        }
      } else {
        // Manipulação sem índice
        const updatedInEdition = { ...inEdition }
        if (field in updatedInEdition) {
          delete updatedInEdition[field]
        } else {
          updatedInEdition[field] = { title: fieldDescription, fieldValue, reason: '' }
        }

        return {
          ...prevState,
          inEdition: updatedInEdition,
        }
      }
    })
  }

  return {
    loading,
    formContext,
    setFormContext,
    openModal,
    handleOpenValidateModal,
    blockUnblockCollaborator,
    submitFormDisabled,
    handleValidateCollaboratorCheckbox,
    emergencyContactsGroup,
    emergencyContactsGroupCount,
    handleAddEmergencyContact,
    handleDeleteEmergencyContact,
    handleEmergencyFieldsChange,
    handleCPFField,
    handleZipcodeField,
  }
}
