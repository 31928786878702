import React from 'react'

export const CardImage = ({ className = '', img, alt = '' }) => {
  return (
    <div className="card-image">
      <figure className={`image is-4by3 ${className}`}>
        <img src={img} alt={alt} />
      </figure>
    </div>
  )
}
