export const createSchemaClientOmie = (values) => {
  const cnpjCpf = values.cpfCnpj.trim().replace(/[.\-/]/g, '')
  const tel1ddd = values.responsavel_telefone.trim().slice(1, 3)
  const tel1 = values.responsavel_telefone
    .trim()
    .slice(values.responsavel_telefone.indexOf(')') + 1)
    .replace(' ', '')
  const pessoaFisica = values.cpfCnpj.length > 15 ? 'N' : 'S'

  return {
    codigo_cliente_integracao: values.id,
    razao_social: values.razaoSocial,
    cnpj_cpf: cnpjCpf,
    nome_fantasia: values.nomeFantasia,
    telefone1_ddd: tel1ddd,
    telefone1_numero: tel1,
    contato: values.reponsavel_name,
    endereco: values.endereco,
    endereco_numero: values.endereco_numero,
    bairro: values.bairro,
    complemento: values.complemento,
    estado: values.uf,
    cidade: values.municipio,
    cep: values.cep,
    email: values.responsavel_email,
    pessoa_fisica: pessoaFisica, // S ou N
    ...(cnpjCpf.length > 12 && {
      inscricao_estadual: values.isncEstadual,
      inscricao_municipal: values.isncMunicipal,
      tipo_atividade: values.tipoAtividade,
      cnae: values.cnae,
    }),

    importado_api: 'S',
    bloquear_faturamento: 'N', // S ou N
    inativo: 'N',
    dadosBancarios: {
      codigo_banco: values.infoBanks_banco,
      agencia: values.infoBanks_agencia,
      conta_corrente: values.infoBanks_conta,
      doc_titular: values.infoBanks_cpf_titular,
      nome_titular: values.infoBanks_name_titular,
      transf_padrao: 'S',
      cChavePix: values.infoBanks_pix,
    },
    ...(values?.tags && {
      tags: [
        {
          tag: values.tags,
        },
      ],
    }),
  }
}

export const createSchemaColabOmie = (values) => {
  const cnpjCpf = values.cpf.trim().replace(/[.\-/]/g, '')
  const tel1ddd = values.emergencyContacts[0].phone.trim().slice(1, 3)
  const tel1 = values.emergencyContacts[0].phone
    .trim()
    .slice(values.emergencyContacts[0].phone.indexOf(')') + 1)
    .replace(' ', '')

  return {
    codigo_cliente_integracao: values.id,
    razao_social: values.nome,
    cnpj_cpf: cnpjCpf,
    telefone1_ddd: tel1ddd,
    telefone1_numero: tel1,
    contato: values.emergencyContacts[0].name,
    endereco: values.logradouro,
    endereco_numero: values.numeroEndereco,
    bairro: values.bairro,
    complemento: values.complemento ?? '',
    estado: values.uf,
    cidade: values.municipio,
    cep: values.cep,
    email: values.email,
    pessoa_fisica: 'S',

    importado_api: 'S',
    bloquear_faturamento: 'N', // S ou N
    inativo: 'N',
    // dadosBancarios: {
    //   codigo_banco: values.infoBanks_banco,
    //   agencia: values.infoBanks_agencia,
    //   conta_corrente: values.infoBanks_conta,
    //   doc_titular: values.infoBanks_cpf_titular,
    //   nome_titular: values.infoBanks_name_titular,
    //   transf_padrao: 'S',
    //   cChavePix: values.pix,
    // },
    ...(values?.tags && {
      tags: [
        {
          tag: 'Colaborador',
        },
      ],
    }),
  }
}

export const createSchemaPlansOmie = (values, type = 'add') => {
  return {
    ...(type === 'add' && {
      intIncluir: {
        cCodIntServ: values.id,
        // nCodServ: values.id, // não necessario até o momento, pois retorna via api
      },
    }),
    ...(type === 'edit' && {
      intEditar: {
        cCodIntServ: values.id,
        // nCodServ: values.id, // não necessario até o momento, pois retorna via api
      },
    }),
    cabecalho: {
      cDescricao: values.name,
      cCodigo: values.id,
      cIdTrib: '01', // pré definido pelo contador
      cCodServMun: '02800', // pré definido pelo contador
      cCodLC116: '1.03', // pré definido pelo contador
      nIdNBS: '115022000', // pré definido pelo contador
      nPrecoUnit: Number(values.value),
      cCodCateg: '1.01.02', // pré definido pelo contador
    },
    descricao: {
      cDescrCompleta: values.desc,
    },
    impostos: {
      nAliqISS: values?.nAliqISS ? Number(values.nAliqISS) : 0,
      cRetISS: values?.cRetISS ? 'S' : 'N', // S/N
      nAliqPIS: values?.nAliqPIS ? Number(values.nAliqPIS) : 0,
      cRetPIS: values?.cRetPIS ? 'S' : 'N', // S/N
      nAliqCOFINS: values?.nAliqCOFINS ? Number(values.nAliqCOFINS) : 0,
      cRetCOFINS: values?.cRetCOFINS ? 'S' : 'N', // S/N
      nAliqCSLL: values?.nAliqCSLL ? Number(values.nAliqCSLL) : 0,
      cRetCSLL: values?.cRetCSLL ? 'S' : 'N', // S/N
      nAliqIR: values?.nAliqIR ? Number(values.nAliqIR) : 0,
      cRetIR: values?.cRetIR ? 'S' : 'N', // S/N
      nAliqINSS: values?.nAliqINSS ? Number(values.nAliqINSS) : 0,
      cRetINSS: values?.cRetINSS ? 'S' : 'N', // S/N
      nRedBaseINSS: values?.nRedBaseINSS ? Number(values.nRedBaseINSS) : 0,
      nRedBasePIS: values?.nRedBasePIS ? Number(values.nRedBasePIS) : 0,
      nRedBaseCOFINS: values?.nRedBaseCOFINS ? Number(values.nRedBaseCOFINS) : 0,
      lDeduzISS: !!values?.lDeduzISS, /// bolean
    },
    produtosUtilizados: {
      cAtualizarProdutos: '', // S / N
      produtoUtilizado: [''],
    },
    // viaUnica: {
    //   cUtilizaViaUnica: '',
    //   cModeloNF: '',
    //   cCFOP: '',
    //   cClassifServico: '',
    //   cTipoReceita: '',
    //   cTipoUtilizacao: '',
    // },
  }
}
