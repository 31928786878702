import React from 'react'

export const ModalButtonClose = ({ setActive }) => {
  const fecharModal = () => {
    setActive(false)
  }

  return (
    <button
      className="modal-close is-large"
      aria-label="close"
      onClick={() => {
        fecharModal()
      }}
    ></button>
  )
}
