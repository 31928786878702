import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { updateDocument } from '../../../../firebase/firestore'
import { useGlobalContext } from '../../../../context/ContextGlobal'

export const useValidateCollaboratorModal = () => {
  const [openModal, setOpenModal] = useState(false)

  const { setAlerts, formContext, setFormContext } = useGlobalContext()

  const { id } = useParams()

  const modalCollaboratorSubmitDisabled = formContext.modalCollaboratorSubmitDisabled ?? true

  const handleOpenValidateModal = () => {
    setOpenModal(!openModal)
  }

  const clearUpdatedInBulkFlags = () => {
    setFormContext((prevContext) => {
      // Copia a estrutura de `inEdition` atual
      const updatedInEdition = { ...prevContext.inEdition }

      // Remove a propriedade `updatedInBulk` de todos os itens
      Object.keys(updatedInEdition).forEach((itemKey) => {
        const item = updatedInEdition[itemKey]

        if (Array.isArray(item)) {
          // Caso seja um array (ex: emergencyContacts), remove a flag de todos os contatos
          item.forEach((contact) => {
            Object.keys(contact).forEach((fieldKey) => {
              if (contact[fieldKey].updatedInBulk) {
                delete contact[fieldKey].updatedInBulk
              }
            })
          })
        } else if (item.updatedInBulk) {
          // Caso seja um item singular, remove a flag diretamente
          delete item.updatedInBulk
        }
      })

      return {
        ...prevContext,
        inEdition: updatedInEdition,
      }
    })
  }

  const handleSendFieldsToFix = async () => {
    clearUpdatedInBulkFlags()

    const editedCollaborator = {
      name: formContext?.nome,
      photoUrl: formContext?.url_foto,
      cpf: formContext?.cpf,
      personal: {
        socialName: formContext?.nomeSocial,
        document: formContext?.documento,
        docType: formContext?.tipoDocumento,
        birthday: formContext?.dtNascimento,
        civilStatus: formContext?.estadoCivil,
      },
      address: {
        street: formContext?.logradouro,
        number: formContext?.numeroEndereco,
        district: formContext?.bairro,
        zipcode: formContext?.cep,
        city: formContext?.cidade,
        state: formContext?.uf,
      },
      contact: { phone: formContext?.celular, emergencyContacts: formContext?.emergencyContacts },
      banking: {
        account: formContext?.conta,
        agency: formContext?.agencia,
        bank: formContext?.banco,
        pix: formContext?.pix,
      },
      consents: { ...formContext?.consentimentos },
      documents: { ...formContext?.documentos },
      positions: { ...formContext?.cargos },
      email: formContext?.email,
      inEdition: { ...(formContext?.inEdition ?? {}) },
      // dt_last_login: DateTimeNow(),
      // version_app: '1.0.0',
      status: formContext?.status,
      // notification_token: 'teste',
    }

    await updateDocument('colaboradores', id, editedCollaborator)

    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Campos selecionados',
        msg: `Os campos foram enviados para correção.`,
      },
    ])
  }

  return {
    openModal,
    handleOpenValidateModal,
    handleSendFieldsToFix,
    modalCollaboratorSubmitDisabled,
  }
}
