import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Page } from '../page'
import { Icon } from '../../icons'
import { Button } from '../../ui/button'
import { Path } from '../../../router/paths'

export const NotPayment = () => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(Path.ClientPayments)
  }

  return (
    <Page.Section className="mt-6 pt-6">
      <Page.Content>
        <div className="has-text-centered">
          <Icon size={25} className="mx-2 has-text-grey-light">
            alert
          </Icon>
          <Icon size={50} className="mx-2 has-text-grey-light">
            bill
          </Icon>
          <Icon size={25} className="mx-2 has-text-grey-light">
            alert
          </Icon>
          <p className="has-text-grey">
            Sua empresa possui débitos em aberto, por favor, realize o pagamento para poder continuar usando o sistema.
          </p>
          <Button.Bt
            action={() => {
              goBack()
            }}
          >
            Ir para pagamentos
          </Button.Bt>
        </div>
      </Page.Content>
    </Page.Section>
  )
}
