import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import {
  addDocument,
  getDocument,
  getDocumentsWithQuery,
  getSubDocuments,
  updateDocument,
  updateSubDocument,
} from '../../../../firebase/firestore'
import { GetPaginationCollection } from '../../../../firebase/functions'
import { SetHistoryEvent } from '../../../../utils/requests'
import { ConvertToTimestamp, DateTimeNow } from '../../../../utils/dates'
// import { GetPaginationCollection } from '../../../../firebase/functions'
export const useClientEvents = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    // formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const navigate = useNavigate()

  const assinaturasDatas = (infosContext?.assinaturas || []).sort((a, b) => {
    return new Date(a.dtStart) - new Date(b.dtStart)
  })
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [statusEvent, setStatusEvent] = useState('todos')
  // const navigate = useNavigate();
  const clientId = user.client.id

  const getDatas = async () => {
    let newPage = 1
    if (page > 0) {
      newPage = page
    }

    const querys = []
    const querysClientId = [{ field: 'clientId', operator: '==', value: clientId }]

    // Campos a serem filtrados
    const fieldsFiltered = ['name']
    const value = String(search).toLowerCase()

    // Adiciona condições de pesquisa para cada campo em fieldsFiltered
    fieldsFiltered.forEach((field) => {
      let querystatus = []
      let querySearch = []
      if (value !== '') {
        querySearch = [
          { field, operator: '>=', value },
          { field, operator: '<=', value: value + '\uf8ff' },
        ]
      }
      if (statusEvent === 'todos') {
        querystatus = [
          { field: 'status', operator: '==', value: 'open' },
          { field: 'status', operator: '==', value: 'closed' },
        ]
        querystatus.forEach((newQuery) => querys.push([...querysClientId, newQuery, ...querySearch]))
      } else {
        querystatus = [{ field: 'status', operator: '==', value: statusEvent }]
        querys.push([...querysClientId, ...querystatus, ...querySearch])
      }
    })

    const getEvents = await GetPaginationCollection(newPage, 50, 'events', 'dtCreated', querys)
    console.log(getEvents)
    const events = {
      ...getEvents,
      data: (getEvents?.data || []).sort((a, b) => ConvertToTimestamp(b.dtCreated) - ConvertToTimestamp(a.dtCreated)),
    }
    console.log(events)
    console.log(querysClientId)
    setDataContext({ events })
  }

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getAssinaturas = await getSubDocuments('clientes', clientId, 'assinaturas')

    setInfosContext({ assinaturas: getAssinaturas })
  }

  const copyEvent = async (idEvent) => {
    if (!user.user.permissions.events_add) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }
    try {
      // Obtém o evento original
      let newEvent = await getDocument('events', idEvent)

      if (!newEvent) {
        console.error('Evento não encontrado!')
        return
      }

      console.log(newEvent)
      const currentName = newEvent.name
      let number = 0
      let newName = currentName

      // Gera um nome único
      while (true) {
        const eventsWithName = await getDocumentsWithQuery('events', 'name', '==', newName)
        if (eventsWithName.length === 0) break // Nome único encontrado
        number += 1
        newName = `${currentName} (${number})`
      }

      // Cria o novo evento com o nome único
      newEvent = {
        ...newEvent,
        name: newName,
      }

      delete newEvent.id

      await addDocument('events', newEvent)

      // Exibe um alerta de sucesso
      await setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Evento Copiado',
          msg: `O evento "${newName}" foi criado a partir do evento "${currentName}" com sucesso.`,
        },
      ])

      // Atualiza as informações relevantes
      await FetchGetInfos()
      await FetchGetDatas()
      await SetHistoryEvent(idEvent, user.user.nome, 'Evento Criado', { copiadoDe: currentName })
    } catch (error) {
      console.error('Erro ao copiar evento:', error)
      await setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Erro',
          msg: 'Ocorreu um erro ao copiar o evento. Por favor, tente novamente.',
        },
      ])
    }
  }

  const deleteEvent = async (idEvent, eventName) => {
    if (!user.user.permissions.events_delete) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }

    const reason = window.prompt('Nos conte o motivo da exclusão desse evento:')

    console.log(reason)
    if (reason === '' || reason === undefined || reason === null) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Motivo obrigatório',
          msg: `É necessario informar um motivo para continuar a deleção do evento`,
        },
      ])
    }

    await updateDocument('events', idEvent, {
      status: 'deleted',
      reasonDel: reason,
      deleteByUser: user.user.nome,
      deleteAt: DateTimeNow(),
    })

    await setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Evento Deletado',
        msg: `O Evento ${eventName} foi deletado com sucesso.`,
      },
    ])

    await FetchGetInfos()
    await FetchGetDatas()
    await SetHistoryEvent(idEvent, user.user.nome, 'Evento Deletado', { motivo: reason })
  }

  const lockUnlockEvent = async (status, idEvent, eventName) => {
    if (!user.user.permissions.jobOffers_openClose) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para realizar essa ação.',
        },
      ])
    }

    const jobOffers = await getSubDocuments('events', idEvent, 'jobOffers')
    const promises = jobOffers.map(async (jobOffer) => {
      const newjob = { status: !status }
      await updateSubDocument('events', idEvent, 'jobOffers', jobOffer.id, newjob)
    })

    await Promise.all(promises)
    await updateDocument('events', idEvent, { openAllJobs: !status })

    await setDataContext((prev) => {
      const data = prev?.events?.data || []
      console.log(data)
      const newevents = data.map((e) => {
        if (e.id === idEvent) {
          return { ...e, openAllJobs: !status }
        }
        return e
      })
      return { ...prev, events: { ...prev.events, data: newevents } }
    })
    await SetHistoryEvent(
      idEvent,
      user.user.nome,
      `${status ? 'Abertura total das vagas' : 'Fechamento total das vagas'}`,
    )
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: `Vagas ${status ? 'Abertas' : 'Fechadas'}`,
        msg: `Todas as vagas desse evento foram ${status ? 'abertas' : 'Fechadas'} para o evento: ${eventName}`,
      },
    ])
  }

  const HandleDeleteEvent = async (idEvent, eventName) => {
    await TryCatch(
      () => {
        deleteEvent(idEvent, eventName)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleCopyEvent = async (idEvent) => {
    await TryCatch(
      async () => {
        await copyEvent(idEvent)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleLockUnlockEvent = async (status, idEvent, eventName) => {
    await TryCatch(
      async () => {
        await lockUnlockEvent(status, idEvent, eventName)
      },
      setLoading,
      setAlerts,
    )
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetInfos()
    setFormContext({})
  }, [])

  useEffect(() => {
    if (page > 0) {
      FetchGetDatas()
    } else {
      setPage(1)
    }
  }, [page, statusEvent])

  return {
    page,
    setPage,
    statusEvent,
    setStatusEvent,
    navigate,
    loading,
    search,
    setSearch,
    assinaturasDatas,
    dataContext,
    setDataContext,
    infosContext,
    FetchGetDatas,
    FetchGetInfos,
    HandleDeleteEvent,
    HandleLockUnlockEvent,
    HandleCopyEvent,
  }
}
