import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { addDocument } from '../../../../firebase/firestore'
import { DateTimeNow, TimestampNow } from '../../../../utils/dates'
import { TryCatch } from '../../../../utils/general'
import { Path } from '../../../../router/paths'
import { SetHistoryEvent } from '../../../../utils/requests'

export const useClientAddEvents = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    // dataContext,
    // setDataContext, // contexto que guarda dados de lista para a pagina
    // infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const navigate = useNavigate()
  console.log(user.user)

  const addEvent = async () => {
    const statusVestments = Number(formContext?.vestments)
    const statusFood = Number(formContext?.food)
    const foodValue = Number(formContext?.foodValue)
    const statustransportAllowance = Number(formContext?.transportAllowance)
    const transportAllowanceValue = Number(formContext?.transportAllowanceValue || 0)
    if (statusVestments === 1 && !formContext?.submitVestments) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Erro no Fornecimento de Vestimentas',
          msg: 'É necessário selecionar ao menos um tipo de vestimenta ao escolher a opção "Fornecer".',
        },
      ])
    }

    if (statusFood === 2 && foodValue <= 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Erro no Valor de Alimentação',
          msg: 'O valor deve ser maior que 0 quando a opção "Incluso no Cachê" está selecionada.',
        },
      ])
    }

    if (statustransportAllowance === 2 && transportAllowanceValue <= 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Erro no Valor de Vale Transporte',
          msg: 'O valor deve ser maior que 0 quando a opção "Incluso no Cachê" está selecionada.',
        },
      ])
    }

    const newEvent = {
      name: formContext?.name,
      round: formContext?.round,
      register: formContext?.register,
      payment: formContext?.payment,
      dtPayment: formContext?.dtPayment || '0000-00-00',
      address: {
        endereco: formContext?.endereco,
        city: formContext?.city,
        uf: formContext?.uf,
        local_encontro: formContext?.local_encontro,
      },
      food: {
        status: statusFood,
        foodValue,
      },
      vestments: {
        status: statusVestments,
        submitVestments: formContext?.submitVestments || [],
      },
      transportAllowance: {
        status: statustransportAllowance,
        transportAllowanceValue,
      },
      infoAdditional: formContext?.infoAdditional || '',
      clientId: user.client.id,
      clientName: user.client.nomeFantasia,
      createdBy: user.user.nome,
      dtCreated: DateTimeNow(),
      timestampCreated: TimestampNow(),
      status: 'open',
      openAllJobs: false,
      ...(formContext?.hasCityRestrictions && { cityRestrictions: formContext?.cityRestrictions || [] }),
      hasCityRestrictions: formContext?.hasCityRestrictions || false,
    }
    // console.log('newEvent: ', newEvent)

    const idNewEvent = await addDocument('events', newEvent)
    console.log(idNewEvent)
    await setFormContext({})
    await SetHistoryEvent(idNewEvent, user.user.nome, `Evento Criado`)
    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Evento Criado',
        msg: `O evento ${newEvent.name} foi criado com sucesso.`,
      },
    ])

    return navigate(Path.ClientEditEvents(idNewEvent))
  }

  const HandleAddEvents = async () => {
    await TryCatch(addEvent, setLoading, setAlerts)
  }

  useEffect(() => {
    setFormContext({ foodValue: 0 })
  }, [])

  return { loading, formContext, setFormContext, HandleAddEvents }
}
