import React from 'react'

import { Page } from '../../../../components/layouts/page'
import { Icon } from '../../../../components/icons'
import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Table } from '../../../../components/ui/table'
import { Input } from '../../../../components/ui/input'
import { useClientPayments } from './_hooks/useClientPayments'
import { colorTagStatusPagamentos } from '../../../administration/clients/_utils'
import { DataComTraçoParaDataComBarra } from '../../../../utils/dates'

export const ClientPayments = () => {
  const { user, loading, search, assinaturasDatas, setSearch, dataFiltered, FetchGetDatas, FetchGetInfos } =
    useClientPayments()

  return (
    <>
      <Page.Root className="is-fullwidth">
        {/* Infomações dos planos */}
        <Column.Root className="mt-6 pt-5">
          <Column.Content>
            <p className="subtitle is-size-3">Pagamentos</p>
          </Column.Content>
          <Column.Content className="has-text-right">
            <Button.Root align="right">
              <Button.Content>
                <Button.Bt
                  color="is-dark"
                  colorText="has-text-white"
                  action={() => {
                    FetchGetDatas()
                    FetchGetInfos()
                  }}
                  loading={loading}
                >
                  <Icon size={15}>sync</Icon>
                </Button.Bt>
              </Button.Content>
            </Button.Root>
          </Column.Content>
        </Column.Root>
        {/* Lista de Assinaturas do cliente */}
        <Column.Root className="is-mobile">
          <Column.Content>
            <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
              <Table.Head>
                <Table.Row key={'table_payments_head'}>
                  <Table.Cell>Assinatura</Table.Cell>
                  <Table.Cell>Valor</Table.Cell>
                  <Table.Cell>Data inicio</Table.Cell>
                  <Table.Cell>Data Término</Table.Cell>
                  <Table.Cell>Situação</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {assinaturasDatas.map((value) => {
                  return (
                    <>
                      <Table.Row key={value.id}>
                        <Table.Cell className="is-size-7 is-vcentered py-3">{value.name}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">R$ {value.value}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.dtStart}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">{value.dtEnd}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">
                          {value.status ? (
                            <>
                              <p className="tag is-success">{'Ativo'}</p>
                            </>
                          ) : (
                            '-'
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )
                })}
              </Table.Body>
            </Table.Root>
          </Column.Content>
        </Column.Root>
        {/* Titulo */}
        <Column.Root className="mt-6 pt-5">
          <Column.Content>
            <p className="subtitle is-size-4">Histórico</p>
          </Column.Content>
          <Column.Content className="has-text-right">Próximo Faturamento: {user.client.dt_nextInvoice}</Column.Content>
        </Column.Root>
        {/* Barra de pesquisa */}
        <Column.Root className="is-mobile">
          <Column.Content>
            <Input.Root>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt value={search} setValue={setSearch} placeholder="Persquisar..."></Input.Prompt>
                <Input.Icon align="is-left">
                  <Icon size={20}>search</Icon>
                </Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        </Column.Root>
        {/* Tabela de dados */}
        <Column.Root className="is-mobile">
          <Column.Content>
            <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
              <Table.Head>
                <Table.Row key={'table_payments_head'}>
                  <Table.Cell>Nº Doc</Table.Cell>
                  <Table.Cell>Vencimento</Table.Cell>
                  <Table.Cell>Valor</Table.Cell>
                  <Table.Cell>Situação</Table.Cell>
                  <Table.Cell>Pagamento</Table.Cell>
                  <Table.Cell>Ações</Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {dataFiltered.map((value) => {
                  return (
                    <>
                      <Table.Row key={value.id}>
                        <Table.Cell className="is-size-7 is-vcentered">
                          {value?.numero_pedido || value?.numero_documento}
                        </Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">
                          {DataComTraçoParaDataComBarra(String(value.dt_vencimento).split('T')[0])}
                        </Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">R$ {value.value}</Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">
                          <p className={`tag ${colorTagStatusPagamentos(value.status)}`}>{value.status}</p>
                        </Table.Cell>
                        <Table.Cell className="is-size-7 is-vcentered">
                          {DataComTraçoParaDataComBarra(String(value?.data_cred || '-').split('T')[0])}
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <div style={{ marginBottom: 5, marginTop: 5 }}>
                            {value.status === 'A vencer' && (
                              <Button.Root align="centered">
                                <Button.Content>
                                  <Button.Bt className={'mx-2'} color="is-success" action={() => {}} loading={loading}>
                                    <Icon size={15}>checkin</Icon>
                                  </Button.Bt>
                                </Button.Content>
                              </Button.Root>
                            )}
                            {value.status === 'Pagamento Realizado' && (
                              <Button.Root align="centered">
                                <Button.Content>
                                  <Button.Bt className={'mx-2'} color="is-light" action={() => {}} loading={loading}>
                                    <Icon size={15}>print</Icon>
                                  </Button.Bt>
                                </Button.Content>
                              </Button.Root>
                            )}
                            {value.status === 'Cancelado' && (
                              <Button.Root align="centered">
                                <Button.Content>
                                  <Button.A className={'mx-2'} color="is-danger" loading={loading} disabled={true}>
                                    <Icon size={15}>print</Icon>
                                  </Button.A>
                                </Button.Content>
                              </Button.Root>
                            )}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )
                })}
              </Table.Body>
            </Table.Root>
          </Column.Content>
        </Column.Root>
      </Page.Root>
    </>
  )
}
