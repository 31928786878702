import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import { GetPaginationCollection } from '../../../../firebase/functions'
import { SetHistorySystem } from '../../../../utils/requests'
import { getDocuments, updateDocument } from '../../../../firebase/firestore'
import { Path } from '../../../../router/paths'
import { ClientOmie } from '../../../../api/omie'
import { DateTimeNow } from '../../../../utils/dates'

export const useAdminClients = () => {
  const { setAlerts, dataContext, setDataContext, setInfosContext, user, setLoading, setFormContext } =
    useGlobalContext()

  const navigate = useNavigate()

  const location = useLocation()

  const [valuePage, setValuePage] = useState(1)

  const [search, setSearch] = useState('')

  const [searchParams, setSearchParams] = useSearchParams()

  // Coleta os dados de paginação da pagina
  const getDatas = async () => {
    await setFormContext({})

    if (location.pathname === Path.AClients) {
      setSearchParams({ page: valuePage, query: search })
    }

    const collectionName = 'clientes'
    const fieldsFiltered = ['nomeFantasia', 'cpfCnpj', 'razaoSocial']
    const orderBy = null
    const limitResultPerPage = 5
    const value = String(search).toLowerCase()
    let querys = []
    if (search !== '') {
      querys = fieldsFiltered.map((field) => [
        { field, operator: '>=', value },
        { field, operator: '<=', value: value + '\uf8ff' },
      ])
    }

    console.log(querys)
    const getpaginationClient = await GetPaginationCollection(
      valuePage,
      limitResultPerPage,
      collectionName,
      orderBy,
      querys,
    )
    // console.log('getpaginationClient:', getpaginationClient)

    return setDataContext((prev) => ({
      ...prev,
      clientes: getpaginationClient,
    }))
  }

  // Coleta informações adicionais que a pagina necessita
  const getInfos = async () => {
    const getPlans = await getDocuments('planos')

    return setInfosContext((prev) => ({
      ...prev,
      planos: getPlans,
    }))
  }

  const DelClient = async (values) => {
    // gera mensagem de erro caso não tenha permissão para excluir
    if (!user?.user?.permissions?.clientes_excluir) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para deletar clientes',
        },
      ])
    }

    const reasonDelete = window.prompt('Digite o motivo da exclusão:')
    // gera mensagem de erro caso não tenha preenchi do motivo da exclusão
    if (!reasonDelete && reasonDelete === '') {
      return setAlerts((prev) => [
        ...prev,
        {
          title: 'Motivo não justificado',
          msg: 'Não foi possivel deletar o cliente, pois você deve inserir um motivo valido.',
          type: 'error',
        },
      ])
    }

    const idClient = values.id
    const idClientOmie = values.idClientOmie

    let inativarClienteOmie = false

    const newclientOmie = { codigo_cliente_omie: idClientOmie, codigo_cliente_integracao: idClient, inativo: 'S' }

    console.log(newclientOmie)
    try {
      inativarClienteOmie = await ClientOmie().alterarCliente([newclientOmie])
    } catch (error) {
      throw new Error(error.message)
    }

    if (!inativarClienteOmie) return

    // Deleta o documento principal do cliente
    await updateDocument('clientes', idClient, {
      status: 'deleted',
      del_dis_By: user.user.nome,
      dt_del_dis: DateTimeNow(),
      del_dis_reason: reasonDelete,
    })

    await SetHistorySystem(
      user?.user?.nome,
      'Exclusão de Cliente',
      `Cliente: ${values.cpfCnpj}; motivo: ${reasonDelete}`,
    )

    // Define alerta de sucesso
    setAlerts((prev) => [
      ...prev,
      {
        title: 'Cliente Excluído',
        msg: `O cliente ${values.nomeFantasia} foi deletado com sucesso!`,
        type: 'success',
      },
    ])

    // Atualiza os dados da página
    await FetchClientData()
  }

  const UpdatePage = async () => {
    window.location.reload()
  }

  const HandleDelClient = async (id, nomeFantasia) => {
    await TryCatch(() => DelClient(id, nomeFantasia), setLoading, setAlerts)
  }

  const FetchClientInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const FetchUpdatePage = async () => {
    await TryCatch(UpdatePage, setLoading, setAlerts)
  }

  const FetchClientData = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }

  const nextPage = async () => {
    const currentPage = dataContext?.clientes?.pagination?.currentPage
    setValuePage(currentPage + 1)
    await FetchClientData()
  }

  const PreviousPage = async () => {
    const currentPage = dataContext?.clientes?.pagination?.currentPage
    setValuePage(currentPage - 1)
    await FetchClientData()
  }

  useEffect(() => {
    // executa somente quando estiver na pagina correta
    if (location.pathname === Path.AClients) {
      FetchClientData()
    }
  }, [valuePage])

  return {
    search,
    setSearch,
    nextPage,
    PreviousPage,
    dataContext,
    HandleDelClient,
    FetchClientData,
    FetchClientInfos,
    FetchUpdatePage,
    valuePage,
    setValuePage,
    searchParams,
    navigate,
  }
}
