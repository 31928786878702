import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  query,
  where,
  deleteField,
  limit as firestoreLimit,
  increment,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore'

import { db } from '.'

/**
 * Gera os documentos da coleção
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const generateIdDocument = async (collectionName) => {
  try {
    const collectionRef = collection(db, collectionName)
    const uniqueId = doc(collectionRef).id
    return uniqueId
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Gera os documentos da coleção
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const generateIdSubDocument = async (collectionName, documentId, SubCollectionName) => {
  try {
    const collectionRef = collection(db, collectionName, documentId, SubCollectionName)
    const uniqueId = doc(collectionRef).id
    return uniqueId
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Coleta todos os documentos da coleção
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getDocuments = async (collectionName) => {
  try {
    const collectionRef = collection(db, collectionName)
    const dataSnap = await getDocs(collectionRef)
    return dataSnap.docs.map((data) => ({ id: data.id, ...data.data() }))
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Coleta todos os documentos de uma subcoleção
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {String} SubCollectionName - Recebe o nome da subcoleção.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getSubDocuments = async (collectionName, documentId, SubCollectionName) => {
  try {
    const collectionRef = collection(db, collectionName, documentId, SubCollectionName)
    const dataSnap = await getDocs(collectionRef)
    return dataSnap.docs.map((data) => ({ ...data.data(), id: data.id }))
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Coleta um documento específico
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getDocument = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() }
    } else {
      throw new Error(`Nenhum documento Encontrando em ${collectionName}>>>${documentId}`)
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Coleta um Subdocumento específico
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {String} SubCollectionName - Recebe o nome da subcoleção.
 * @param {String} SubDocumentId - Recebe o nome do subdocumento.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getSubDocument = async (collectionName, documentId, SubCollectionName, SubDocumentId) => {
  try {
    const docRef = doc(db, collectionName, documentId, SubCollectionName, SubDocumentId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() }
    } else {
      throw new Error(
        `Nenhum documento Encontrando em ${collectionName}>>>${documentId}>>>${SubCollectionName}>>>${SubDocumentId}`,
      )
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Adiciona um documento com ID aleatório
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const addDocument = async (collectionName, values) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), values)
    return docRef.id
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Adiciona um subDocumento com ID aleatório
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const addSubDocument = async (collectionName, documentId, subCollecionName, values) => {
  try {
    const docRef = await addDoc(collection(db, collectionName, documentId, subCollecionName), values)
    return docRef.id
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Adiciona um documento passando um ID especifico
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const setDocument = async (collectionName, documentId, values) => {
  try {
    const docRef = doc(db, collectionName, documentId)
    await setDoc(docRef, values)
    return documentId
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Adiciona um documento passando um ID especifico
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const setSubDocument = async (collectionName, documentId, subCollectionName, subDocument, values) => {
  try {
    const docRef = doc(db, collectionName, documentId, subCollectionName, subDocument)
    await setDoc(docRef, values)
    return documentId
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Atualiza o documento passando o ID e os valores que deve atualizar
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const updateDocument = async (collectionName, documentId, values) => {
  try {
    console.log(collectionName, documentId, values)
    const docRef = doc(db, collectionName, documentId)
    await updateDoc(docRef, values)
    return documentId
  } catch (error) {
    throw new Error(`Error: ${error} || Params: ${collectionName}, ${documentId}, ${values}`)
  }
}

/**
 * Atualiza o SubDocumento passando o ID e os valores que deve atualizar
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const updateSubDocument = async (collectionName, documentId, subCollectionName, subDocumentId, values) => {
  try {
    const docRef = doc(db, collectionName, documentId, subCollectionName, subDocumentId)
    await updateDoc(docRef, values)
    return documentId
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Atualiza um array em um subdocumento no Firestore
 *
 * @param {String} collectionName - Nome da coleção principal.
 * @param {String} documentId - ID do documento principal.
 * @param {String} subCollectionName - Nome da subcoleção.
 * @param {String} subDocumentId - ID do subdocumento.
 * @param {String} field - Nome do campo do array que será atualizado.
 * @param {Array} values - Valores a serem adicionados ou removidos.
 * @param {Boolean} isAdding - Define se os valores serão adicionados (true) ou removidos (false).
 * @returns {Object} - Retorna o ID do subdocumento atualizado.
 */
export const updateArrayInSubDocument = async (
  collectionName,
  documentId,
  subCollectionName,
  subDocumentId,
  field,
  values,
  isAdding = true,
) => {
  try {
    const docRef = doc(db, collectionName, documentId, subCollectionName, subDocumentId)

    // Define a operação a ser realizada no array
    const updateValues = isAdding ? arrayUnion(...values) : arrayRemove(...values)

    // Atualiza o documento
    await updateDoc(docRef, {
      [field]: updateValues,
    })

    return { id: subDocumentId, message: 'Array atualizado com sucesso' }
  } catch (error) {
    throw new Error(`Erro ao atualizar o array: ${error.message}`)
  }
}

/**
 * Atualiza um array em um documento no Firestore
 *
 * @param {String} collectionName - Nome da coleção.
 * @param {String} documentId - ID do documento.
 * @param {String} field - Nome do campo do array que será atualizado.
 * @param {Array} values - Valores a serem adicionados ou removidos.
 * @param {Boolean} isAdding - Define se os valores serão adicionados (true) ou removidos (false).
 * @returns {Object} - Retorna o ID do documento atualizado e uma mensagem de sucesso.
 */
export const updateArrayInDocument = async (collectionName, documentId, field, values, isAdding = true) => {
  try {
    const docRef = doc(db, collectionName, documentId)

    // Define a operação a ser realizada no array
    console.log('values: ', values)
    const updateValues = isAdding ? arrayUnion(...values) : arrayRemove(...values)

    // Atualiza o documento
    await updateDoc(docRef, {
      [field]: updateValues,
    })

    return { id: documentId, message: 'Array atualizado com sucesso' }
  } catch (error) {
    throw new Error(`Erro ao atualizar o array: ${error.message}`)
  }
}

/**
 * Deleta o documento com base no ID especificado
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const deleteDocument = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId)
    await deleteDoc(docRef)
    return documentId
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Deleta o subDocumento com base no ID especificado
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const deleteSubDocument = async (collectionName, documentId, subCollectionName, SubDocumentId) => {
  try {
    const docRef = doc(db, collectionName, documentId, subCollectionName, SubDocumentId)
    await deleteDoc(docRef)
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Deleta um campo especifico do documento.
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {String} field - Recebe o campo que deve ser deletado  | users.uid  .
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const deleteDocumentField = async (collectionName, documentId, field) => {
  try {
    const docRef = doc(db, collectionName, documentId)
    await updateDoc(docRef, {
      [`${field}`]: deleteField(),
    })
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Função para escutar atualizações em tempo real de uma coleção
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {SetValue} callback - Recebe uma variavel para setar os valores atualizados.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getDocumentsRealtime = (collectionName, callback) => {
  const colRef = collection(db, collectionName)
  return onSnapshot(
    colRef,
    (snapshot) => {
      const dataSnap = snapshot.docs.map((data) => ({
        id: data.id,
        ...data.data(),
      }))
      callback(dataSnap)
    },
    (error) => {
      console.error('Error get documents in real-time:', error)
    },
  )
}

/**
 * Função para escutar atualizações em tempo real de um documento específico
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {SetValue} callback - Recebe uma variavel para setar os valores atualizados.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getDocumentRealtime = (collectionName, documentId, callback) => {
  const docRef = doc(db, collectionName, documentId)
  return onSnapshot(
    docRef,
    (docSnapshot) => {
      const dataSnap = { id: docSnapshot.id, ...docSnapshot.data() }
      callback(dataSnap)
    },
    (error) => {
      throw new Error(error.message)
    },
  )
}

/**
 * Função para realizar uma consulta de documentos com filtro
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} field - Recebe o nome de um campo do documento a ser pesquisado.
 * @param {String} operator - Recebe um tipo de operador ("==", ">", "<", ">=", "<=", "!=").
 * @param {string} values - Recebe um valor a ser pesquisado.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getDocumentsWithQuery = async (collectionName, field, operator, value) => {
  try {
    const q = query(collection(db, collectionName), where(field, operator, value))
    const querySnapshot = await getDocs(q)
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * Função para realizar uma consulta de subDocumentos com filtro
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} field - Recebe o nome de um campo do documento a ser pesquisado.
 * @param {String} operator - Recebe um tipo de operador ("==", ">", "<", ">=", "<=", "!=").
 * @param {string} values - Recebe um valor a ser pesquisado.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */

export const getSubDocumentsWithQuery = async (
  collectionName,
  documentId,
  SubcollectionName,
  field,
  operator,
  value,
  limit = null,
) => {
  try {
    // Cria a consulta com a cláusula where
    const q = query(
      collection(db, collectionName, documentId, SubcollectionName),
      where(field, operator, value),
      ...(limit ? [firestoreLimit(limit)] : []), // Aplica o limite, se fornecido
    )

    const querySnapshot = await getDocs(q)
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))

    return data
  } catch (error) {
    throw new Error(`Error fetching subdocuments: ${error.message}`) // Mensagem de erro mais específica
  }
}

/**
 * Função para realizar uma consulta de subDocumentos com filtro
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {String} SubcollectionName - Recebe o nome da sub coleção pesquisada.
 * @param {Array} querys - Recebe um array de objetos: [ { field: 'campo pesquisado', operator: ("==", ">", "<", ">=", "<=", "!="), value: 'valor pesquisado'}]
 * @param {Number} limit - Recebe um number de limit.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 *
 */
export const getDocumentsWithQuerys = async (collectionName, querys = [], limit = null) => {
  try {
    // Cria a consulta com a cláusula where
    const q = query(
      collection(db, collectionName),
      ...querys.map((q) => where(q.field, q.operator, q.value)),
      ...(limit ? [firestoreLimit(limit)] : []), // Aplica o limite, se fornecido
    )

    const querySnapshot = await getDocs(q)
    const data = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))

    return data
  } catch (error) {
    throw new Error(`Error fetching subdocuments: ${error.message}`) // Mensagem de erro mais específica
  }
}

/**
 * Função para realizar uma consulta de subDocumentos com filtro
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {String} SubcollectionName - Recebe o nome da sub coleção pesquisada.
 * @param {Array} querys - Recebe um array de objetos: [ { field: 'campo pesquisado', operator: ("==", ">", "<", ">=", "<=", "!="), value: 'valor pesquisado'}]
 * @param {Number} limit - Recebe um number de limit.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 *
 */
export const getSubDocumentsWithQuerys = async (
  collectionName,
  documentId,
  SubcollectionName,
  querys = [],
  limit = null,
) => {
  try {
    // Cria a consulta com a cláusula where
    const q = query(
      collection(db, collectionName, documentId, SubcollectionName),
      ...querys.map((q) => where(q.field, q.operator, q.value)),
      ...(limit ? [firestoreLimit(limit)] : []), // Aplica o limite, se fornecido
    )

    const querySnapshot = await getDocs(q)
    const data = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))

    return data
  } catch (error) {
    throw new Error(`Error fetching subdocuments: ${error.message}`) // Mensagem de erro mais específica
  }
}

/**
 * Deleta todos os documentos de uma subcoleção
 *
 * @param {String} collectionName - Nome da coleção principal.
 * @param {String} documentId - Nome do documento pai.
 * @param {String} subCollectionName - Nome da subcoleção a ser deletada.
 * @returns {String} - Retorna o ID do documento principal ao qual a subcoleção pertencia.
 * @throws {Error} - Lança um erro se houver um problema ao deletar os documentos.
 */
export const deleteSubCollection = async (collectionName, documentId, subCollectionName) => {
  try {
    const subCollectionRef = collection(db, collectionName, documentId, subCollectionName)

    // Obtém todos os documentos da subcoleção
    const querySnapshot = await getDocs(subCollectionRef)

    const deletePromises = querySnapshot.docs.map(async (docSnapshot) => {
      const docRef = doc(db, collectionName, documentId, subCollectionName, docSnapshot.id)
      await deleteDoc(docRef)
    })

    Promise.all(deletePromises)
  } catch (error) {
    throw new Error(error.message)
  }
}

/**
 * Incrementa o campo de um documento passando o ID e o valor a ser incrementado
 *
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {String} fieldName - Nome do campo a ser incrementado.
 * @param {Number} incrementValue - Valor a ser incrementado no campo.
 * @returns {String} - Retorna o ID do documento.
 */
export const incrementFieldInDocument = async (collectionName, documentId, fieldName, incrementValue) => {
  try {
    const docRef = doc(db, collectionName, documentId)
    await updateDoc(docRef, {
      [fieldName]: increment(incrementValue), // Incrementa o valor do campo
    })
    return documentId
  } catch (error) {
    throw new Error(`Error: ${error} || Params: ${collectionName}, ${documentId}, ${fieldName}, ${incrementValue}`)
  }
}

/**
 * Incrementa o campo de um documento dentro de uma subcoleção
 *
 * @param {String} collectionName - Nome da coleção principal.
 * @param {String} documentId - ID do documento principal.
 * @param {String} subcollectionName - Nome da subcoleção.
 * @param {String} subDocumentId - ID do documento dentro da subcoleção.
 * @param {String} fieldName - Nome do campo a ser incrementado.
 * @param {Number} incrementValue - Valor a ser incrementado no campo.
 * @returns {String} - Retorna o ID do documento da subcoleção.
 */
export const incrementFieldInSubDocument = async (
  collectionName,
  documentId,
  subcollectionName,
  subDocumentId,
  fieldName,
  incrementValue,
) => {
  try {
    // Referência ao documento na subcoleção
    const docRef = doc(db, collectionName, documentId, subcollectionName, subDocumentId)

    // Atualiza o campo na subcoleção
    await updateDoc(docRef, {
      [fieldName]: increment(incrementValue), // Incrementa o valor do campo
    })

    return subDocumentId
  } catch (error) {
    throw new Error(
      `Error: ${error} || Params: ${collectionName}, ${documentId}, ${subcollectionName}, ${subDocumentId}, ${fieldName}, ${incrementValue}`,
    )
  }
}

// export const testeDeConsulta = async () => {
//   const fieldsSearched = ['nomeFantasia', 'cpfCnpj', 'razaoSocial']
//   const search = 'Entreprise'
//   const collectionData = []

//   const queries = fieldsSearched.map((field) => {
//     const collectionref = collection(db, 'clientes')
//     const q = query(
//       collectionref,
//       where(field, '>=', search),
//       where(field, '<=', search + '~'),
//     )

//     return getDocs(q) // Retorna a promessa da consulta
//   })

//   // Executa todas as consultas em paralelo
//   const queryResults = await Promise.all(queries)
//   const addedIds = new Set() // Para armazenar IDs já inseridos

//   console.log('queryResults: ', queryResults)

//   // Processa os resultados das consultas
//   queryResults.forEach((snapshot) => {
//     console.log('snapshot: ', snapshot.docs)
//     snapshot.docs.forEach((doc) => {
//       console.log('doc: ', doc)
//       if (!addedIds.has(doc.id)) {
//         collectionData.push({
//           id: doc.id,
//           ...doc.data(),
//         })
//         addedIds.add(doc.id) // Marca o ID como adicionado
//       }
//     })
//   })

//   return collectionData
// }
