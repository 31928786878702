import { Protections } from '.'

export const ProtectionGlobal = ({
  children,
  permission = undefined,
  required = undefined,
  status = 'active',
  redirectTo = '/',
}) => {
  return (
    <Protections.Redirect permission={required} redirectTo={redirectTo}>
      <Protections.Permition permission={permission}>
        <Protections.Payment permission={status}>{children}</Protections.Payment>
      </Protections.Permition>
    </Protections.Redirect>
  )
}
