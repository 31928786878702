import { useEffect } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import { addSubDocument, getSubDocuments, getSubDocumentsWithQuerys } from '../../../../firebase/firestore'
import { getAddressByCep } from '../../../../utils/consultingCEP'

export const useClientFormEvents = (values, setValues, options, clientId = null) => {
  const { user, setLoading, setAlerts, setInfosContext, infosContext, formContext, setFormContext } = useGlobalContext()
  const vestmentList = [
    'camiseta',
    'calça',
    'bermuda',
    'vestido',
    'saia',
    'terno',
    'blazer',
    'social',
    'sapato',
    'tenis',
    'boné',
    'jaqueta',
    'colete',
  ]

  const getInfos = async () => {
    const getcitys = await getSubDocuments('clientes', user.client.id, 'citys')
    setInfosContext((prev) => ({ ...prev, citys: getcitys }))
  }

  const addVestments = async (vestiment) => {
    console.log(vestiment)

    // Verifica se a vestimenta é inválida
    if (!vestiment) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Vestimenta Inválida',
          msg: 'Por favor, selecione uma vestimenta válida para adicioná-la ao evento.',
        },
      ])
    }

    // Atualiza o estado com a nova vestimenta
    await setValues((prev) => {
      // Remove a chave 'select_Vestment' do estado anterior
      const { selectVestment, ...rest } = prev

      return {
        ...rest,
        submitVestments: [...(prev?.submitVestments || []), vestiment],
      }
    })
  }

  const addCity = async () => {
    const type = formContext?.newCity || false
    let newCity
    // adicionar um evento
    if (type) {
      if (!user.user.permissions.city_add) {
        return setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Permissão Negada',
            msg: 'Você não possui permissão para realizar essa ação.',
          },
        ])
      }
      const cep = formContext?.inputCEP || null
      if (!cep) {
        return setAlerts((prev) => [
          ...prev,
          { type: 'error', title: 'Cep Obrigatório', msg: 'Digite um cep válido para continuar' },
        ])
      }

      const infosCep = await getAddressByCep(cep)

      if (infosCep?.error) {
        return setAlerts((prev) => [...prev, { type: 'error', title: 'Erro ao buscar cep', msg: infosCep?.error }])
      }

      const city = infosCep.localidade
      const uf = infosCep.uf
      const query = [
        { field: 'city', operator: '==', value: city },
        { field: 'uf', operator: '==', value: uf },
      ]
      const exist = await getSubDocumentsWithQuerys('clientes', user.client.id, 'citys', query)

      newCity = { city, uf }

      if (Array.isArray(exist) && exist.length === 0) {
        await addSubDocument('clientes', user.client.id, 'citys', newCity)
      }
    } else {
      const selectCityRestrictions = formContext?.selectCityRestrictions || null

      const city = infosContext?.citys?.find((values) => values.id === selectCityRestrictions) || null

      if (!selectCityRestrictions && !city) {
        return setAlerts((prev) => [
          ...prev,
          { type: 'error', title: 'Selecione uma cidade', msg: 'Para continuar, selecione uma cidade permitida...' },
        ])
      }

      newCity = { city: city.city, uf: city.uf }
    }

    await FetchGetInfos()
    setFormContext((prev) => {
      const cityRestrictions = (prev?.cityRestrictions || []).filter((values) => {
        // Filtra as cidades que não possuem a mesma cidade e UF
        return values.city !== newCity.city || values.uf !== newCity.uf
      })

      // Retorna o novo contexto com a cidade adicionada
      return { ...prev, cityRestrictions: [newCity, ...cityRestrictions] }
    })
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Cidade Adicionada',
        msg: `A Cidade "${newCity.city}" foi adicionada com sucesso na sua base de dados.`,
      },
    ])
  }

  const deleteCity = async (values) => {
    const city = values.city
    const uf = values.uf

    setFormContext((prev) => {
      const cityRestrictions = (prev?.cityRestrictions || []).filter((values) => {
        // Filtra as cidades que não possuem a mesma cidade e UF
        return values.city !== city || values.uf !== uf
      })

      // Retorna o novo contexto com a cidade adicionada
      return { ...prev, cityRestrictions: [...cityRestrictions] }
    })
  }

  const delVestments = async (vestiment) => {
    let submitVestmentsCurrent = values?.submitVestments || []

    if (submitVestmentsCurrent) {
      submitVestmentsCurrent = submitVestmentsCurrent.filter((vests) => {
        return vests !== vestiment
      })
      await setValues((prev) => ({
        ...prev,
        submitVestments: submitVestmentsCurrent,
      }))
    }
  }

  const HandleAddVestments = async (vestments) => {
    await TryCatch(
      () => {
        addVestments(vestments)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleDeleteVestments = async (vestments) => {
    await TryCatch(
      () => {
        delVestments(vestments)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleDeleteCity = async (values) => {
    await TryCatch(
      async () => {
        await deleteCity(values)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleAddCity = async () => {
    await TryCatch(addCity, setLoading, setAlerts)
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  useEffect(() => {
    FetchGetInfos()
    console.log(infosContext)
  }, [])

  return { infosContext, vestmentList, HandleAddVestments, HandleDeleteVestments, HandleAddCity, HandleDeleteCity }
}
